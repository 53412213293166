export const modalClasses = `

.CENTER_SCREEN-enter {
  > div {
    margin-bottom: -100%;
  }
}
.CENTER_SCREEN-enter-active {
  > div {
    margin-bottom: 0;
    transition: all 300ms ease-out;
  }
}
.CENTER_SCREEN-exit {
  > div {
    margin-bottom: 0;
  }
}
.CENTER_SCREEN-exit-active {
  > div {
    margin-bottom: -100%;
    transition: all 300ms ease-in;
  }
}

.FULL_SCREEN-enter {
  > div {
    margin-bottom: -100%;
  }
}
.FULL_SCREEN-enter-active {
  > div {
    margin-bottom: 0;
    transition: all 300ms ease-out;
  }
}
.FULL_SCREEN-exit {
  > div {
    margin-bottom: 0;
  }
}
.FULL_SCREEN-exit-active {
  > div {
    margin-bottom: -100%;
    transition: all 300ms ease-in;
  }
}
.CENTER_SCREEN-enter {
  > div {
    margin-bottom: -100%;
  }
}
.CENTER_SCREEN-enter-active {
  > div {
    margin-bottom: 0;
    transition: all 300ms ease-out;
  }
}
.CENTER_SCREEN-exit {
  > div {
    margin-bottom: 0;
  }
}
.CENTER_SCREEN-exit-active {
  > div {
    margin-bottom: -100%;
    transition: all 300ms ease-in;
  }
}

.FULL_SCREEN-enter {
  > div {
    margin-bottom: -100%;
  }
}
.FULL_SCREEN-enter-active {
  > div {
    margin-bottom: 0;
    transition: all 300ms ease-out;
  }
}
.FULL_SCREEN-exit {
  > div {
    margin-bottom: 0;
  }
}
.FULL_SCREEN-exit-active {
  > div {
    margin-bottom: -100%;
    transition: all 300ms ease-in;
  }
}
`;
