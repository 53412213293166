import {fetchInstance, setAuthToken} from "wrappers/axios";
import {ADMIN_CLIENT_URL, api} from "config/api";
import {AppActions} from "reducers/app";

import {getGlobalUser, getUserFolios, getUserProfile} from "actions/user";
import {UserActions} from "reducers/user";
import {authParams, ROLES} from "config/consts";
import {getParameterByName} from "utils/getParameterByName";

export const clearSessionData = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("session_user_id");
  setAuthToken();
};

export const authLogin = (params, savePassword, history) => {
  return (dispatch) => {
    return fetchInstance({
      method: "POST",
      url: api.authLogin,
      data: {
        ...params,
        grant_type: "password",
        ...authParams,
      },
    })
      .then(async (response) => {
        clearSessionData();
        const {access_token, refresh_token} = response.data;
        const isMobile = window.innerWidth <= 768;
        setAuthToken(access_token);
        const {role_name} = await dispatch(getUserProfile());
        if (
          (role_name === ROLES.ADMIN || role_name === ROLES.MANAGER) &&
          !getParameterByName("redirect") &&
          !isMobile
        ) {
          localStorage.setItem("access_token", access_token);
          window.location = ADMIN_CLIENT_URL + `?token=${access_token}`;
        } else {
          localStorage.setItem("access_token", access_token);
          if (savePassword) {
            localStorage.setItem("refresh_token", refresh_token);
            dispatch(AppActions.setTokens({access_token, refresh_token}));
          } else {
            dispatch(AppActions.setTokens({access_token, refresh_token: null}));
          }
          dispatch(AppActions.setAuth(true));
          dispatch(getUserProfile());
          dispatch(getUserFolios(true, history));
        }
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };
};

export const checkToken = (token) => {
  return (dispatch, getState) => {
    const {userId} = getState().user;
    let access_token = token,
      refresh_token;
    if (!token) {
      access_token = localStorage.getItem("access_token");
      refresh_token = localStorage.getItem("refresh_token");
    }
    setAuthToken(access_token);
    fetchInstance({
      method: "GET",
      url: userId ? api.getUserProfileById(userId) : api.getUserProfile,
    })
      .then((response) => {
        //response.data.data.show_tutorial = 1;
        if (userId) dispatch(getGlobalUser());
        dispatch(UserActions.setProfile(response.data.data));
        dispatch(AppActions.setAuth(true));
        dispatch(AppActions.setCheckedToken());
        dispatch(getUserFolios());
        //dispatch(getAccountId());

        dispatch(
          AppActions.setTokens({access_token, refresh_token: refresh_token || null})
        );
      })
      .catch(() => {
        setAuthToken();
        dispatch(AppActions.setCheckedToken());
      });
  };
};

export const authRegister = (data) => {
  return new Promise((resolve, reject) => {
    fetchInstance({
      method: "POST",
      url: api.authRegister,
      data: {
        ...data,
        name: data.firstName,
      },
    })
      .then(resolve)
      .catch(reject);
  });
};

export const authSendResetEmail = (data) => {
  return new Promise((resolve, reject) => {
    fetchInstance({
      method: "POST",
      url: api.authSendResetEmail,
      data,
    })
      .then(resolve)
      .catch(reject);
  });
};

export const authLogout = () => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: api.authLogout,
    }).then(() => {
      clearSessionData();
      window.location.reload();
    });
  };
};

export const authResetPassword = (data) => {
  return fetchInstance({
    method: "POST",
    url: api.authResetPassword,
    data,
  });
};

export const authUpdatePassword = (data) => {
  return fetchInstance({
    method: "POST",
    url: api.authUpdatePassword,
    data,
  });
};

export const authIsValidToken = (data) => {
  return new Promise((resolve, reject) => {
    fetchInstance({
      method: "POST",
      url: api.authIsValidToken,
      data,
    })
      .then(resolve)
      .catch(reject);
  });
};

export const registrationSteps = (step, data) => {
  return fetchInstance({
    method: "POST",
    url: api.registrationSteps(step),
    data,
  });
};

export const authLoginPaymentFlow = (params) => {
  return (dispatch) => {
    return fetchInstance({
      method: "POST",
      url: api.authLogin,
      data: {
        ...params,
        grant_type: "password",
        ...authParams,
      },
    })
      .then(async (response) => {
        const {access_token} = response.data;
        setAuthToken(access_token);
        localStorage.setItem("access_token", access_token);
        dispatch(AppActions.setTokens({access_token, refresh_token: null}));
        dispatch(AppActions.setAuth(true));
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };
};

export const authGetEmail = (hash) => {
  return fetchInstance({
    method: "POST",
    url: api.authInvite,
    data: {
      hash,
    },
  });
};
