export const fontSizeFrom = 8;
const fontSizeTo = 48;
const step = 1;

const fontSizes = [],
  fontSizesCustom = [];
for (let i = fontSizeFrom; i <= fontSizeTo; i += step) {
  fontSizes.push(`${i}px`);
  fontSizes.push(`${i}pt`);
}
for (let i = 1; i <= fontSizeTo; i += 1) {
  fontSizesCustom.push(`${i}px`);
  fontSizesCustom.push(`${i}pt`);
}
export {fontSizes};

let fontSizesClass = "";
fontSizes.forEach((size) => (fontSizesClass += `.ql-size-${size}{font-size:${size};}`));
fontSizesCustom.forEach(
  (size) => (fontSizesClass += `.custom-size-${size}{font-size:${size}!important;}`)
);
export {fontSizesClass};

const fonts = [
  {
    value: "Arial",
    label: "Arial",
    types: ["regular", "bold", "black"],
  },
  {
    value: "Georgia",
    label: "Georgia",
    types: ["regular", "bold"],
  },
  {
    value: "Impact",
    label: "Impact",
    types: ["regular"],
  },
  {
    value: "Tahoma",
    label: "Tahoma",
    types: ["regular", "bold"],
  },
  {
    value: "TimesNewRoman",
    label: "Times New Roman",
    types: ["regular", "bold"],
  },
  {
    value: "Verdana",
    label: "Verdana",
    types: ["regular", "bold"],
  },
  {
    value: "Avenir",
    label: "Avenir",
    types: ["light", "medium", "heavy", "black"],
  },
  {
    value: "HelveticaNeue",
    label: "HelveticaNeue",
    types: ["light", "medium", "heavy"],
  },
  {
    value: "Roboto",
    label: "Roboto",
    types: ["light", "regular", "medium", "bold", "black"],
  },
  {
    value: "RobotoSlab",
    label: "RobotoSlab",
    types: ["light", "regular", "medium", "bold", "black"],
  },
  {
    value: "Tungsten",
    label: "Tungsten",
    types: ["light", "medium", "semibold", "bold"],
  },
  {
    value: "Montserrat",
    label: "Montserrat",
    types: ["light", "regular", "medium", "bold", "extrabold"],
  },
  {
    value: "OpenSans",
    label: "OpenSans",
    types: ["light", "regular", "medium", "bold", "extrabold"],
  },
  {
    value: "Lato",
    label: "Lato",
    types: ["light", "regular", "bold", "black"],
  },
  {
    value: "Merriweather",
    label: "Merriweather",
    types: ["light", "regular", "bold", "black"],
  },
  {
    value: "Oswald",
    label: "Oswald",
    types: ["light", "regular", "medium", "bold"],
  },
  {
    value: "SourceSans",
    label: "SourceSans",
    types: ["light", "regular", "bold", "black"],
  },
  {
    value: "Garamond",
    label: "Garamond",
    types: ["regular", "medium", "bold", "extrabold"],
  },
  {
    value: "Titillium",
    label: "Titillium",
    types: ["light", "regular", "bold", "black"],
  },
  {
    value: "Vitesse",
    label: "Vitesse",
    types: ["thin", "light", "book", "medium", "bold", "black"],
  },
  {
    value: "Zuume",
    label: "Zuume",
    types: [
      "extralight",
      "light",
      "regular",
      "medium",
      "bold",
      "semibold",
      "extrabold",
      "black",
    ],
  },
];

export const oldFonts = [
  {value: "Arial", types: []},
  {value: "Georgia", types: []},
  {value: "Impact", types: []},
  {value: "Tahoma", types: []},
  {value: "TimesNewRoman", types: []},
  {value: "Verdana", types: []},
  {value: "Roboto", types: []},
  {value: "RobotoSlab", types: []},
  {value: "Avenir", types: ["Light", "Heavy"]},
  {value: "HelveticaNeue", types: ["Light", "Heavy"]},
  {value: "Tungsten", types: ["Light", "Medium", "Semibold", "Bold"]},
];

const oldFontsList = oldFonts.reduce((accumulator, currentValue) => {
  if (currentValue.types.length) {
    const fontTypes = currentValue.types.map((type) => {
      return `${currentValue.value}${type}`;
    });
    return [...accumulator, ...fontTypes];
  } else {
    return [...accumulator, currentValue.value];
  }
}, []);

export const fontsList = fonts
  .sort((a, b) => {
    if (a.value > b.value) {
      return 1;
    } else if (a.value < b.value) {
      return -1;
    } else {
      return 1;
    }
  })
  .reduce((accumulator, currentValue) => {
    const fontTypes = currentValue.types.map((type) => {
      return `${currentValue.value}-${type}`;
    });
    return [...accumulator, ...fontTypes];
  }, []);

export const fontsWhitelist = [...fontsList, ...oldFontsList];
