import {themeBreakpoints} from "config/consts";

export const theme = {
  variables: {
    blockMargin: 7,
    blockHeaderHeight: 40,
    "z-index-modal": 999999,
  },
  fonts: {
    fontFamily: {
      montserratNormal: "'Montserrat-regular', Arial-regular, sans-serif",
      montserratExtraBold:
        "'Montserrat-extrabold', 'Montserrat-bold', Arial-regular, sans-serif",
      montserratBold: "'Montserrat-bold', Arial-regular, sans-serif",
      montserratSemibold: "'Montserrat-medium', Arial-regular, sans-serif",
      avenir: "'Avenir-light', 'Montserrat-regular', 'Helvetica-Neue'",
      avenirMedium: "'Avenir-medium', 'Montserrat-medium', 'HelveticaNeue-medium'",
      avenirBold: "'Avenir-heavy', 'Montserrat-bold', 'HelveticaNeue-heavy'",
      helveticaNeueLight: "'HelveticaNeue-light', Arial-regular, sans-serif",
      helveticaNeueMedium: "'HelveticaNeue-medium', Arial-regular, sans-serif",
      helveticaNeueBold: "'HelveticaNeue-heavy', Arial-regular, sans-serif",
    },
  },
  colors: {
    accent: "#25BEC8",
    main: "#FFFFFF",
    dark: "#1F2741",
    backgroundAccent: "#f8fbff",
    backgroundAccent2: "rgb(247, 247, 247)",
    backgroundAccent3: "rgba(201, 205, 212, 0.3)",
    backgroundAccent4: "#f4f4f4",
    backgroundAccent5: "linear-gradient(to right, #20dac4 1%, #00c4cc 99%)",
    lightBorder: "#C5D9E8",
    lightBorder2: "rgba(183, 210, 229, 0.8)",
    lightBorder3: "#cfeffc",
    lightAccent: "#cbddea",
    lightText: "#6A7984",
    lightText2: "#5c575e",
    lightText3: "#5e5c65",
    lightText4: "#3c435a",
    lightText5: "#515979",
    lightText6: "#475778",
    lightText7: "#656b6f",
    lightText8: "#3f536e",
    darkText: "#182241",
    darkText2: "#242E4B",
    darkText3: "#324060",
    darkText4: "#202741",
    darkAccent: "rgba(31, 39, 65, 0.87)",
    placeholderAccent: "#787c80",
    placeholderAccent2: "#9097b0",
    linkAccent: "#0093ee",
    analyticsAccent: "#41c6cf",
    mobileAccent: "#26cdd8",
    hover: "#ebf2f7",
    mobileBorder: "rgba(31, 39, 65, .1)",
    noteAccent: "#fd6864",
  },
  breakpoints: {
    values: themeBreakpoints,
  },
  animations: {
    transition: ".2s ease-in-out",
  },
};
