import React, {memo} from "react";

const Heroes2rectVideo = memo(() => (
  <svg viewBox="0 0 380 176">
    <rect fill={"#defafa"} width="380" height="176" rx="3.83" ry="3.83" />
    <rect fill={"#069696"} x="120" y="23" width="140" height="76" />
    <path
      fill="#fff"
      d="M180 36.3c-1.9.6-5.8 3.4-8.5 6.2-6.8 6.7-9.4 14.8-7.6 23 3.7 16.5 21.4 26.1 36.1 19.4 5.5-2.5 10.5-7.1 13.3-12.4 2.8-5.2 3-17.4.4-22.2-6.8-12.6-20.4-18.2-33.7-14zm20.9 6c8.2 5.5 12.1 16.1 9.1 25.1-1.1 3.6-3 4.9-3 2.2 0-1.9-5.7-6.6-7.8-6.6-.9 0-2.6.7-3.6 1.5-2.6 2-9.1 1.9-12.6-.2-4-2.3-7.5-.6-10 4.9l-1.9 4.3-1.5-3.7c-3.3-7.7-1.4-18.2 4.2-24.2 6.8-7.2 19-8.7 27.1-3.3z"
    />
    <path
      fill="#fff"
      d="M184.5 47.5c-2.4 2.3-2.9 3.9-2.6 8 .2 2.5 5.5 7.5 7.9 7.5 4.4 0 10-7.6 8.5-11.7-2.2-6.4-9.3-8.4-13.8-3.8z"
    />
    <rect fill={"#069696"} x="127.67" y="128.33" width="126.5" height="6.17" />
    <rect fill={"#84d1d1"} x="127.67" y="143.71" width="126.5" height="2.54" />
    <rect fill={"#84d1d1"} x="127.67" y="154.71" width="126.5" height="2.54" />
  </svg>
));

export default Heroes2rectVideo;
