import {useMediaQuery} from "react-responsive";

export const Desktop = ({children}) => {
  const isDesktop = useMediaQuery({minWidth: 769});
  return isDesktop ? children : null;
};
export const Tablet = ({children}) => {
  const isTablet = useMediaQuery({minWidth: 769, maxWidth: 1023});
  return isTablet ? children : null;
};
export const Mobile = ({children}) => {
  const isMobile = useMediaQuery({maxWidth: 768});
  return isMobile ? children : null;
};
