import React, {createContext, memo, useEffect, useRef} from "react";
import {useMediaQuery} from "react-responsive";
import {useHelper} from "../../hooks/useHelper";
import {useDispatch, useSelector} from "react-redux";
import StartHelperModal from "./HelperContent/StartModal";
import {show} from "redux-modal";
import {useLocation} from "react-router-dom";
import {folioEditPrefix} from "../../config/routes";
// import {updateUser} from "../../actions/user";

export const HelperContext = createContext();

const Helper = memo(({config, children}) => {
  const dispatch = useDispatch();
  const {isFetchingConfig} = useSelector((state) => state.folio);
  const mobile = useMediaQuery({maxWidth: 768});
  const showTutorial =
    useSelector((state) => state.user.profile.show_tutorial) &&
    !mobile &&
    !localStorage.getItem("session_user_id");
  const showTutorialModal =
    useSelector((state) => state.user.profile.show_mobile_tutorial) && mobile;
  const alreadyStarted = useRef(false);
  const location = useLocation();
  const timer = useRef();

  // const dispatch = useDispatch();
  // const userId = useSelector((state) => state.user.profile.id);

  const {start, helper, handleReadyState, handlePauseState} = useHelper({
    config,
    show: showTutorial,
  });

  useEffect(() => {
    clearTimeout(timer.current);
    if (
      !alreadyStarted.current &&
      !isFetchingConfig &&
      location.pathname.indexOf(folioEditPrefix) === 0
    ) {
      timer.current = setTimeout(() => {
        alreadyStarted.current = true;
        if (showTutorial) {
          dispatch(show("helper-start-modal"));
        }
        if (showTutorialModal) {
          dispatch(show("tutorial-modal"));
        }
      }, 1000);
    }
    return () => clearTimeout(timer.current);
  }, [dispatch, isFetchingConfig, location.pathname, showTutorial, showTutorialModal]);

  return (
    <HelperContext.Provider value={{handleReadyState, handlePauseState}}>
      {/*<button*/}
      {/*  onClick={() => dispatch(updateUser({show_tutorial: true}, userId))}*/}
      {/*></button>*/}
      {children}
      {helper}
      <StartHelperModal start={start} />
    </HelperContext.Provider>
  );
});

Helper.displayName = "Helper";

export default Helper;
