import React, {memo} from "react";
import {Icon} from "components/Icon";
import CheckIcon from "icons/CheckIcon";
import styled from "styled-components";

const ListOverlay = memo(() => {
  return (
    <Overlay>
      <Icon icon={CheckIcon} size={50} color={"#fff"} noCursor />
    </Overlay>
  );
});

ListOverlay.displayName = "ListOverlay";

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 190, 200, 0.5);
  align-items: center;
  justify-content: center;
  display: flex;
`;

export {ListOverlay};
