import React, {memo} from "react";

const Heroes1 = memo(() => (
  <svg viewBox="0 0 380 176">
    <rect fill="#defafa" width="380" height="176" rx="3.83" ry="3.83" />
    <circle fill="#069696" cx="188.88" cy="88" r="39" />
    <circle fill="#fff" cx="190" cy="83.58" r="6.92" />
    <path
      fill="#fff"
      d="M189.69,66.75A22.25,22.25,0,1,0,211.94,89,22.25,22.25,0,0,0,189.69,66.75ZM205,99.85c-.53-2.74-2.22-8.81-6.7-8.81-4,0-3.54,3.5-8.25,3.5s-4.25-3.5-8.25-3.5c-4.9,0-6.46,7.25-6.82,9.48a18.75,18.75,0,1,1,30-.67Z"
    />
  </svg>
));

Heroes1.displayName = "Heroes1";

export default Heroes1;
