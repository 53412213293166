import React from "react";
import {Icon} from "./components/Icon";
// import ChangeBlockIcon from "./modules/Block/BlockEdit/ChangeBlockIcon";
import {IconMove} from "./icons/IconMove";
// import DeleteBlockIcon from "./modules/Block/BlockEdit/DeleteBlockIcon";
import {IconWidth} from "./icons/IconWidth";
import {IconChangeColor} from "./icons/IconChangeColor";
import CropIcon from "./components/Icons/CropIcon";
import AddMediaIcon from "./components/Icons/AddMediaIcon";
import {ButtonLayout} from "./components/ButtonLayout";
import {IconBlockLayout} from "./icons/IconBlockLayout";
import {IconBlockDuplicate} from "./icons/IconBlockDuplicate";
import {IconBlockDelete} from "./icons/IconBlockDelete";

export const editingHeaderAddition = [
  {
    id: 0,
    icon: <Icon icon={IconBlockLayout} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Header Layouts",
  },
];

export const editingBlocksAddition = [
  {
    id: 0,
    icon: <Icon icon={IconMove} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Moving Blocks",
    options: {
      width: "50%",
    },
  },
  {
    id: 1,
    icon: <Icon icon={IconBlockDuplicate} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Duplicate Block",
    options: {
      width: "50%",
    },
  },
  {
    id: 2,
    icon: <Icon icon={IconBlockLayout} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Edit Block Layout",
    options: {
      width: "50%",
    },
  },
  {
    id: 3,
    icon: <Icon icon={IconBlockDelete} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Delete Block",
    options: {
      width: "50%",
    },
  },
  {
    id: 4,
    icon: <Icon icon={IconWidth} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Edit Block Width",
    options: {
      width: "50%",
    },
  },
  {
    id: 5,
    icon: <Icon icon={IconChangeColor} opacity={0.6} color={"#25BEC8"} noCursor />,
    label: "Edit Background Color",
    options: {
      width: "50%",
    },
  },
];

export const addingMediaAddition = [
  {
    id: 0,
    icon: <ButtonLayout icon={AddMediaIcon} size={32} padding={0} />,
    label: "Add Media",
  },
  {
    id: 1,
    icon: <ButtonLayout icon={CropIcon} size={32} padding={0} />,
    label: "Crop Image",
  },
];
