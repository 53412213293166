import React, {memo} from "react";

const RangeYearIcon = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        fill="#FFF"
        d="M21,6 L21,20 C21,21.1 20.1,22 19,22 L5,22 C3.89,22 3,21.1 3,20 L3.01,6 C3.01,4.9 3.89,4 5,4 L6,4 L6,2 L8,2 L8,4 L16,4 L16,2 L18,2 L18,4 L19,4 C20.1,4 21,4.9 21,6 Z M5,8 L19,8 L19,6 L5,6 L5,8 Z M19,20 L19,10 L5,10 L5,20 L19,20 Z"
      />
      <polygon
        fill="#FFF"
        fillRule="nonzero"
        points="17.414 11 13 15.414 13 19 11 19 11 15.414 6.586 11 9.414 11 12 13.585 14.586 11"
      />
    </g>
  </svg>
));

export default RangeYearIcon;
