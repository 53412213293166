import React, {memo} from "react";

const CheckIcon = memo(() => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 50 50">
      <path
        d="M46.26,9.7c-1.49-1.46-4.1-1.47-5.59,0L17.38,32.55l-7.64-7.49c-1.49-1.46-4.09-1.47-5.59,0
    c-1.54,1.51-1.54,3.97,0,5.48L14.6,40.79c0.75,0.72,1.74,1.12,2.78,1.12c1.06,0,2.05-0.4,2.79-1.13l26.08-25.59
    c0.75-0.73,1.16-1.71,1.16-2.74C47.41,11.41,47,10.44,46.26,9.7z"
      />
    </svg>
  );
});

CheckIcon.displayName = "CheckIcon";

export default CheckIcon;
