import {useEffect} from "react";

export const useKeyClick = (callback, code = 27) => {
  const escFunction = (event) => {
    if (event.keyCode === code) {
      callback(event);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });
};
