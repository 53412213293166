import React, {memo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import capitalize from "lodash/capitalize";
import {ListOverlay} from "components/ListOverlay";

const List = memo(
  ({items, onClick, title, itemsInRow, controls, backplate, selectedValue}) => {
    return (
      <Wrapper>
        {title && (
          <Title>
            {title}
            <Line />
          </Title>
        )}
        <Content>
          {items.map((item, i) => (
            <Item key={i} onClick={() => onClick(item)} width={itemsInRow}>
              <ItemImage backplate={backplate}>
                {item.icon}
                {item.value === selectedValue && <ListOverlay />}
              </ItemImage>
              <ItemTitle>{capitalize(item.title)}</ItemTitle>
            </Item>
          ))}
        </Content>
        {controls && <Controls>{controls}</Controls>}
      </Wrapper>
    );
  }
);

List.displayName = "List";

const Wrapper = styled.div`
  margin-bottom: 48px;
  width: 100%;
`;

const Title = styled.div`
  color: ${({theme}) => theme.colors.main};
  font-size: 20px;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratBold};
  margin-bottom: 40px;
  position: relative;
`;

const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({width}) => 100 / width - 3}%;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Item} {
    margin-right: 3%;
    :last-child {
      margin-right: 0;
    }
  }
`;

const ItemImage = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  background-color: ${({backplate}) => backplate && "#fff"};
  > svg {
    width: 100%;
    height: 100%;
  }
`;

const ItemTitle = styled.div`
  color: ${({theme}) => theme.colors.main};
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  width: 92px;
  bottom: -10px;
  height: 2px;
  background-color: ${({theme}) => theme.colors.accent};
`;

const Controls = styled.div``;

List.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  backplate: PropTypes.bool,
  itemsInRow: PropTypes.number,
  controls: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

List.defaultProps = {
  backplate: true,
  itemsInRow: 5,
  selectedValue: "",
};

export default List;
