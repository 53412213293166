import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {createPortal} from "react-dom";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";
import {HelperContext} from "../wrappers/Helper";
import HelperTooltip from "../components/HelperTooltip";
import SkipModal from "../pages/Folio/Modals/SkipModal";
import styled from "styled-components";
import {useLocation} from "react-router-dom";

export const useHelper = ({config: configProps, autoStart = false, show}) => {
  const mobile = useMediaQuery({maxWidth: 768});
  const showTutorial =
    useSelector((state) => state.user.profile.show_tutorial) &&
    !mobile &&
    !localStorage.getItem("session_user_id");
  const [config, setConfig] = useState(
    configProps.map((el) => ({...el, ready: !el.selector}))
  );
  const configLength = useMemo(() => {
    return configProps.length;
  }, [configProps]);
  const [paused, setPaused] = useState(false);
  const [skipped, setSkipped] = useState(false);
  const [step, setStep] = useState(0);
  const [hidden, setHidden] = useState(false);
  const location = useLocation();
  const isHelperRoutes =
    location.pathname.indexOf("/edit/") === 0 ||
    location.pathname.indexOf("/ifolio/statistics/") === 0;

  useEffect(() => {
    setConfig(configProps.map((el) => ({...el, ready: true})));
    setStep(0);
  }, [configProps]);

  const handleConfig = useCallback((selector, ready) => {
    setConfig((s) =>
      s.map((el) => {
        if (el.selector === selector) {
          return {
            ...el,
            ready,
          };
        }
        return el;
      })
    );
  }, []);

  const handleReadyState = useCallback(
    (selector) => {
      handleConfig(selector, true);
    },
    [handleConfig]
  );

  const handlePauseState = useCallback(
    (selector) => {
      handleConfig(selector, false);
    },
    [handleConfig]
  );

  const lastStep = useRef(step);
  const [portal, setPortal] = useState();

  const handleStep = useCallback(
    (direction) => {
      setStep((state) => {
        let newStep = state + direction;
        if (newStep < 0) {
          newStep = 0;
        } else if (newStep > configLength - 1) {
          newStep = configLength - 1;
        }
        lastStep.current = state;
        return newStep;
      });
    },
    [configLength]
  );

  const handleDone = useCallback(() => {
    setSkipped(true);
  }, []);

  const handlePortal = useCallback(
    (selector) => {
      const el = document.querySelector(`[data-helper="${selector}"]`);
      if (el || !selector) {
        let box;
        if (el) {
          el.scrollIntoView({
            block: "center",
          });
          box = el.getBoundingClientRect();
        } else {
          const width = configProps[step].width || 300;
          const height = configProps[step].height || 200;
          box = {
            width,
            height: 1,
            top: (window.innerHeight - height) / 2,
            left: (window.innerWidth - width) / 2,
          };
        }
        setPortal(
          createPortal(
            <HelperTooltip
              step={step}
              config={configProps}
              hideArrow={!selector}
              handleStep={handleStep}
              box={{...JSON.parse(JSON.stringify(box)), top: box.top + window.scrollY}}
              setPaused={setPaused}
              setDone={handleDone}
            >
              {configProps[step].content}
            </HelperTooltip>,
            document.body
          )
        );
        setHidden(false);
      } else {
        if (step < configProps.length - 1) {
          setStep((state) => state + 1);
        } else {
          setHidden(true);
        }
      }
    },
    [configProps, handleDone, handleStep, step]
  );

  const portalTimer = useRef();

  const handleElement = useCallback(() => {
    clearTimeout(portalTimer.current);
    const stepConfig = config[step];
    const lastStepConfig = config[lastStep.current];

    const handleStepConfig = () => {
      if (stepConfig?.action) {
        setPortal(undefined);
        portalTimer.current = setTimeout(() => {
          stepConfig.action().then(() => {
            if (stepConfig.ready) {
              handlePortal(stepConfig.selector);
            }
          });
        }, 500);
      } else {
        if (stepConfig.ready) {
          portalTimer.current = setTimeout(() => {
            handlePortal(stepConfig.selector);
          }, 500);
        }
      }
    };
    if (step < lastStep.current) {
      if (lastStepConfig?.prevAction) {
        lastStepConfig.prevAction().then(() => {
          handleStepConfig();
        });
      } else {
        handleStepConfig();
      }
    } else {
      handleStepConfig();
    }
  }, [config, handlePortal, step]);

  const helperIsStarted = useRef(false);

  const start = useCallback(
    (isStarted = false) => {
      if (isStarted) helperIsStarted.current = true;
      if (isHelperRoutes) handleElement();
    },
    [handleElement, isHelperRoutes]
  );

  useEffect(() => {
    if (helperIsStarted.current && showTutorial) {
      start();
    }
  }, [showTutorial, start, step]);

  // console.count(`${step + 1}/${configLength}`);

  return {
    start,
    helper: (
      <>
        {!skipped && !hidden && show && isHelperRoutes ? (
          <Overlay paused={paused}>
            {paused && <SkipModal setPaused={setPaused} setSkipped={setSkipped} />}
            {portal}
          </Overlay>
        ) : null}
      </>
    ),
    handleReadyState,
    handlePauseState,
  };
};

const Overlay = styled.div`
  position: fixed;
  z-index: ${({paused}) => (paused ? 9999999 : 9999)};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.01);
`;

export const useHelperContext = (selector, customEvent = false) => {
  const context = useContext(HelperContext);
  let handleReadyState = useCallback(() => null, []);
  let handlePauseState = useCallback(() => null, []);
  if (context?.handleReadyState) handleReadyState = context.handleReadyState;
  if (context?.handlePauseState) handlePauseState = context.handlePauseState;

  useEffect(() => {
    if (!customEvent) handleReadyState(selector);
    return () => {
      handlePauseState(selector);
    };
  }, [customEvent, handlePauseState, handleReadyState, selector]);

  return {handleReadyState, handlePauseState};
};
