export const scrollbarClasses = `

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-button {
  // background: rgba(255, 255, 255, 1);
  height: 0px;
}

body {
  overscroll-behavior: contain;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
}

body::-webkit-scrollbar-corner {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
body::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.4);
}
body::-webkit-scrollbar-thumb:vertical {
  min-height: 5px;
  min-width: 5px;
}
body::-webkit-scrollbar-thumb:horizontal{
  min-width: 5px;
  min-height: 5px;
  }
`;
