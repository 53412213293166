import axios from "axios";
import {
  api,
  BASE_URL,
  BASE_URL_ANALYTICS,
  BASE_URL_DOMAIN,
  BASE_SHARE_URL,
} from "config/api";
import get from "lodash/get";
import {isPublicRoute} from "utils/checkRoutes";
import {toast} from "react-toastify";
import {addQueryParam} from "utils/addQueryParams";
import {authParams, topUpError} from "config/consts";
import {history} from "routes/history";
import {PRIVATE_ROUTES, PUBLIC_ROUTES} from "config/routes";

export const fetchInstance = axios.create({
  baseURL: BASE_URL,
});
export const fetchInstanceShare = axios.create({
  baseURL: BASE_SHARE_URL,
});

export const fetchInstanceBaseDomain = axios.create({
  baseURL: BASE_URL_DOMAIN,
});

export const fetchInstanceAnalytics = axios.create({
  baseURL: BASE_URL_ANALYTICS,
});

fetchInstanceAnalytics.interceptors.request.use(
  (req) => {
    req.url = addQueryParam(req.url, "_", Date.now());
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    //applying token
    fetchInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    fetchInstanceBaseDomain.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    //deleting the token from header
    delete fetchInstance.defaults.headers.common["Authorization"];
    delete fetchInstanceBaseDomain.defaults.headers.common["Authorization"];
  }
};

fetchInstance.interceptors.request.use(
  (req) => {
    req.url = addQueryParam(req.url, "_", Date.now());
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fetchInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = get(error.response, "status");
    let errorMessage;
    if (status === 400) {
      const {messages, trace} = get(error.response, "data.error", {});
      if (trace) console.log("trace => status === 400", trace);
      errorMessage = messages || [];
      // Probably Sharing subsystem error response ?
      if (
        Array.isArray(errorMessage) &&
        errorMessage.length === 0 &&
        error.response?.data
      ) {
        errorMessage = get(error.response, "data", {});
      }
    } else if (status === 401) {
      if (localStorage.getItem("refresh_token")) {
        localStorage.setItem("refresh_token_fetching", "true");
        fetchInstance({
          method: "POST",
          url: api.authRefreshToken,
          data: {
            refresh_token: localStorage.getItem("refresh_token"),
            grant_type: "refresh_token",
            ...authParams,
          },
        })
          .then((response) => {
            setAuthToken(response.data.access_token);
            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            history.push(window.location.pathname || PRIVATE_ROUTES.FOLIO.path);
          })
          .catch(() => {
            if (!isPublicRoute()) {
              errorMessage = get(error.response, "data.error", "Unknown Error");
            }
          })
          .finally(() => {
            localStorage.removeItem("refresh_token_fetching");
          });
      } else if (!isPublicRoute()) {
        history.push(PUBLIC_ROUTES.AUTH.path);
        window.location.reload();
        // errorMessage = get(error.response, "data.error", "Unknown Error");
      }
    } else if (status === 403) {
      const {error: errorText, detail} = get(error.response, "data", {});
      if (detail) console.log("detail => status === 403", detail);
      if (!isPublicRoute()) errorMessage = errorText;
    } else if (status === 422) {
      const {error: errorText, trace} = get(error.response, "data", {});
      if (trace) console.log("trace => status === 422", trace);
      errorMessage = errorText;
    }
    let err = "";
    if (typeof errorMessage === "string") {
      err = errorMessage;
    } else if (Array.isArray(errorMessage)) {
      errorMessage.forEach((message) => {
        if (typeof message === "string") {
          err += message + "\n";
        }
      });
    } else if (typeof errorMessage === "object" && errorMessage !== null) {
      Object.keys(errorMessage).forEach((key) => {
        const value = errorMessage[key];
        if (typeof value === "string") {
          err += `${value}\n`;
        } else if (Array.isArray(value)) {
          value.forEach((errorText) => {
            if (typeof errorText === "string") {
              err += `${errorText}\n`;
            }
          });
        }
      });
    }
    if (err) {
      // todo change with error code
      if (!err.includes(topUpError)) {
        toast.error(err);
      }
    }
    return Promise.reject(error);
  }
);
