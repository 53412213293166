import React, {memo, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {Icon} from "../Icon";

const Button = memo(
  ({
    theme,
    onClick,
    options,
    icon,
    disabled,
    iconSize,
    iconColor,
    iconPosition,
    active,
    onIconClick,
    helperSelector,
    iconFirst,
    titleWordBreak,
  }) => {
    const {
      color,
      colorChange,
      border,
      borderColor,
      borderRadius,
      textColorChange,
      textColor,
      padding,
      fontSize,
      borderColorChange,
      textTransform,
      font,
      textSpacing,
      minWidth,
      needAlign,
      disabledColor,
    } = useMemo(() => ({...Button.defaultProps.theme, ...theme}), [theme]);

    return (
      <ButtonStyled
        color={color}
        colorChange={colorChange}
        border={border}
        borderColor={borderColor}
        borderColorChange={borderColorChange}
        borderRadius={borderRadius}
        textColorChange={textColorChange}
        onClick={onClick}
        textColor={textColor}
        padding={padding}
        font={font}
        fontSize={fontSize}
        disabled={disabled}
        active={active}
        textTransform={textTransform}
        textSpacing={textSpacing}
        minWidth={minWidth}
        needAlign={needAlign}
        disabledColor={disabledColor}
        data-helper={helperSelector}
      >
        {icon && iconFirst && (
          <IconWrapper
            onClick={onIconClick}
            iconPosition={iconPosition}
            iconFirst={iconFirst}
          >
            <Icon icon={icon} color={iconColor || textColor} size={iconSize || 20} />
          </IconWrapper>
        )}
        <TitleWrapper
          titleWordBreak={titleWordBreak}
          iconPosition={iconPosition}
          iconSize={iconSize}
        >
          {options.title}
        </TitleWrapper>
        {icon && !iconFirst && (
          <IconWrapper
            onClick={onIconClick}
            iconPosition={iconPosition}
            iconFirst={iconFirst}
          >
            <Icon icon={icon} color={iconColor || textColor} size={iconSize || 20} />
          </IconWrapper>
        )}
      </ButtonStyled>
    );
  }
);

Button.displayName = "Button";

const RoundedButton = memo(
  ({
    theme,
    onClick,
    options,
    icon,
    disabled,
    iconColor,
    iconSize,
    iconPosition,
    children,
    active,
    onIconClick,
    iconFirst,
    helperSelector,
    titleWordBreak,
  }) => {
    const {
      color,
      colorChange,
      border,
      borderColor,
      borderRadius,
      textColorChange,
      textColor,
      padding,
      fontSize,
      borderColorChange,
      textTransform,
      font,
      textSpacing,
      minWidth,
      needAlign,
      disabledColor,
    } = useMemo(() => ({...RoundedButton.defaultProps.theme, ...theme}), [theme]);

    return (
      <ButtonStyled
        color={color}
        colorChange={colorChange}
        border={border}
        borderColor={borderColor}
        borderColorChange={borderColorChange}
        borderRadius={borderRadius}
        textColorChange={textColorChange}
        onClick={disabled ? () => {} : onClick}
        textColor={textColor}
        padding={padding}
        fontSize={fontSize}
        disabled={disabled}
        active={active}
        textTransform={textTransform}
        font={font}
        textSpacing={textSpacing}
        minWidth={minWidth}
        needAlign={needAlign}
        disabledColor={disabledColor}
        data-helper={helperSelector}
      >
        {icon && iconFirst && (
          <IconWrapper
            onClick={onIconClick}
            iconPosition={iconPosition}
            iconFirst={iconFirst}
          >
            <Icon icon={icon} color={iconColor || textColor} size={iconSize || 20} />
          </IconWrapper>
        )}
        <TitleWrapper
          titleWordBreak={titleWordBreak}
          iconPosition={iconPosition}
          iconSize={iconSize}
        >
          {options.title}
        </TitleWrapper>
        {icon && !iconFirst && (
          <IconWrapper
            onClick={onIconClick}
            iconPosition={iconPosition}
            iconFirst={iconFirst}
          >
            <Icon icon={icon} color={iconColor || textColor} size={iconSize || 20} />
          </IconWrapper>
        )}
        {children}
      </ButtonStyled>
    );
  }
);

RoundedButton.displayName = "RoundedButton";

const ButtonStyled = styled.div`
  outline: none;
  position: relative;
  font-family: ${({font}) => font};
  font-size: ${({fontSize}) => fontSize};
  text-transform: ${({textTransform}) => textTransform};
  transition: all ease-in-out 0.3s;
  letter-spacing: ${({textSpacing}) => (textSpacing ? textSpacing : "1px")};
  cursor: pointer;
  padding: ${({padding}) => padding};
  background: ${(props) => props.color};
  color: ${(props) => props.textColor};
  border: ${(props) => props.border}px ${({borderColor}) => borderColor} solid;
  border-radius: ${(props) => props.borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: ${({needAlign}) => (needAlign ? needAlign : "center")};
  pointer-events: ${({disabled}) => (disabled ? "none" : "")};
  min-width: ${({minWidth}) => (minWidth ? minWidth : "unset")};
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.sm}px) {
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.xs}px) {
    letter-spacing: 0;
    padding: 6px 15px;
  }

  ${({active}) =>
    active &&
    css`
      background: ${(props) => props.colorChange};
      border: ${(props) => props.border}px ${({borderColorChange}) => borderColorChange}
        solid;
      color: ${(props) => props.textColorChange};
      outline: none;
      svg {
        fill: ${(props) => props.textColorChange};
      }
    `}

  &:hover,
  &:active {
    background: ${(props) => props.colorChange};
    border: ${(props) => props.border}px ${({borderColorChange}) => borderColorChange}
      solid;
    color: ${(props) => props.textColorChange};
    outline: none;
    svg {
      fill: ${(props) => props.textColorChange};
    }
  }

  :focus {
    outline: none;
  }

  ${({disabled}) =>
    disabled
      ? css`
          opacity: ${({disabledColor}) => (disabledColor ? "1" : ".3")};
          background: ${({disabledColor}) => disabledColor || ""};
        `
      : null};
`;

const TitleWrapper = styled.div`
  word-break: ${({titleWordBreak}) => (titleWordBreak ? titleWordBreak : "unset")};
  ${({iconPosition, iconSize}) =>
    iconPosition === "right"
      ? css`
          margin-right: ${iconSize || 20}px;
        `
      : css``}
  ${({iconPosition, iconSize}) =>
    iconPosition === "left"
      ? css`
          margin-left: ${iconSize || 20}px;
        `
      : css``}
}
`;

const IconWrapper = styled.div`
  margin: ${({iconFirst}) => (iconFirst ? "0 16px 0 0" : "0 0 0 8px")};
  position: ${({iconPosition}) => iconPosition && "absolute"};
  ${({iconPosition}) =>
    iconPosition === "right"
      ? css`
          right: 12px;
        `
      : css``}
  ${({iconPosition}) =>
    iconPosition === "left"
      ? css`
          left: 5px;
          margin-bottom: 0;
        `
      : css``}
`;

const propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  theme: PropTypes.shape({
    color: PropTypes.string,
    colorChange: PropTypes.string,
    border: PropTypes.number,
    borderRadius: PropTypes.number,
    textColor: PropTypes.string,
    textColorChange: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,
    borderColor: PropTypes.string,
    borderColorChange: PropTypes.string,
    textTransform: PropTypes.string,
    font: PropTypes.string,
    textSpacing: PropTypes.string,
    minWidth: PropTypes.string,
    needAlign: PropTypes.string,
  }),
  onClick: PropTypes.func,
  onIconClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  iconPosition: PropTypes.oneOf(["right", "left", ""]),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  iconFirst: PropTypes.bool,
  titleWordBreak: PropTypes.string,
};

Button.propTypes = propTypes;
RoundedButton.propTypes = propTypes;

const defaultProps = {
  options: {
    title: "",
  },
  theme: {
    color: "#25BEC8",
    colorChange: "#FFFFFF",
    border: 1,
    borderColor: "#fff",
    borderColorChange: "#fff",
    textColor: "#fff",
    textColorChange: "#25BEC8",
    borderRadius: 3,
    padding: "7px 14px 9px",
    font: "'Montserrat-bold', Arial-regular, sans-serif",
    fontSize: "13px",
    textTransform: "uppercase",
    minWidth: "unset",
    needAlign: "center",
  },
  disabled: false,
  onClick: () => null,
  onIconClick: () => null,
  iconPosition: "",
  iconFirst: false,
  titleWordBreak: "",
};

Button.defaultProps = {
  ...defaultProps,
  theme: {
    ...defaultProps.theme,
  },
};

RoundedButton.defaultProps = {
  ...defaultProps,
  theme: {
    ...defaultProps.theme,
    border: 0,
    borderRadius: 20,
    padding: "6px 16px",
    textColorChange: "#fff",
    textTransform: "uppercase",
  },
};

export {Button};
export {RoundedButton};
export {ButtonStyled};
