export const addQueryParam = (src, name, value) => {
  if (!src || typeof src !== "string") return src;
  let url,
    withOrigin = true;
  try {
    url = new URL(src);
  } catch (e) {
    url = new URL(`${window.location.origin}${src}`);
    withOrigin = false;
  }
  url.searchParams.set(name, value);
  if (!withOrigin) {
    return url.href.replace(window.location.origin, "");
  }
  return url.href;
  // if (src.indexOf("?") !== -1) {
  //   return src + "&" + name + "=" + value;
  // }
  // return src + "?" + name + "=" + value;
};
