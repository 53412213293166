import {configureStore, combineReducers} from "@reduxjs/toolkit";
import {folioSlice} from "reducers/folio";
import {appSlice} from "reducers/app";
import {contentSlice} from "reducers/content";
import {mediaSlice} from "reducers/media";
import {userSlice} from "reducers/user";
import {geoSlice} from "reducers/geo";
import {reducer as modal} from "redux-modal";
import {statisticsSlice} from "reducers/statistics";

const combinedReducer = combineReducers({
  app: appSlice.reducer,
  folio: folioSlice.reducer,
  content: contentSlice.reducer,
  media: mediaSlice.reducer,
  user: userSlice.reducer,
  geo: geoSlice.reducer,
  statistics: statisticsSlice.reducer,
  modal,
});

const rootReducer = (state, action) => {
  // if (action.type === "app/logout") {
  //   state = undefined;
  // return undefined;
  // }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  devTools: true,
});
