import {createSlice} from "@reduxjs/toolkit";
import get from "lodash/get";
import set from "lodash/set";
import {v4 as uuidv4} from "uuid";
import {emptyPlaceholderText} from "../config/consts";

const setBlockContent = ({oldContent, newContent, withAccordion}) => {
  const content = {...oldContent};
  ["text", "media"].forEach((key) => {
    const value = oldContent[key];
    const newValue = newContent[key];
    content[key] = value;
    const oldLength = value.length,
      newLength = newValue.length;
    if (withAccordion && key === "text") {
      content[key] = value;
    } else if (oldLength !== newLength) {
      if (oldLength < newLength) {
        content[key] = [...value, ...newValue.slice(oldLength, newLength)];
      } else {
        content[key] = value.slice(0, newLength);
      }
    }
  });

  let oldContentChartData = [];
  if (content?.custom) {
    oldContentChartData = [...content?.custom];
  }
  let newContentChartData = [];
  if (newContent?.custom) {
    newContentChartData = [...newContent?.custom];
  }
  if (oldContentChartData?.length) {
    oldContentChartData = oldContentChartData.filter((item) => item.type === "chart");
  }
  if (newContentChartData?.length) {
    newContentChartData = newContentChartData.filter((item) => item.type === "chart");
    const oldLength = oldContentChartData?.length ? oldContentChartData.length : 0;
    const newLength = newContentChartData.length;
    if (oldLength !== newLength) {
      if (oldLength < newLength) {
        newContentChartData = [
          ...oldContentChartData,
          ...newContentChartData.slice(oldLength, newLength),
        ];
      } else {
        newContentChartData = oldContentChartData.slice(0, newLength);
      }
    }
  }
  content.custom = newContentChartData;

  return content;
};

export const folioSlice = createSlice({
  name: "folio",
  initialState: {
    structure: [],
    hero: null,
    isFetchingConfig: false,
    isFetchingFolioCreate: false,
    isFetchingFolioDuplicate: false,
    isFetchingFolioDelete: false,
    isSendingShareForm: false,
    isEdit: true,
    folioConfig: {},
    accountDetails: {},
    isQuillToolbarActive: false,
    isQuillCustomButtonToolbarExpanded: false,
  },
  reducers: {
    fulfilledAccountDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    pendingFolioConfig: (state) => {
      state.isFetchingConfig = true;
    },
    fulfilledFolioConfig: (state, action) => {
      state.isFetchingConfig = false;
      state.hero = action.payload.hero;
      state.structure = action.payload.structure;
      state.folioConfig = action.payload.folioConfig;
      localStorage.setItem("iFolioAccountId", action.payload.folioConfig?.account?.id);
    },
    setFolioConfig: (state, action) => {
      state.folioConfig = action.payload;
    },
    rejectedFolioConfig: (state) => {
      state.isFetchingConfig = false;
    },
    startPendingFolioCreate: (state) => {
      state.isFetchingFolioCreate = true;
    },
    endPendingFolioCreate: (state) => {
      state.isFetchingFolioCreate = false;
    },
    startPendingFolioDuplicate: (state) => {
      state.isFetchingFolioDuplicate = true;
    },
    endPendingFolioDuplicate: (state) => {
      state.isFetchingFolioDuplicate = false;
    },
    startPendingFolioDelete: (state) => {
      state.isFetchingFolioDelete = true;
    },
    endPendingFolioDelete: (state) => {
      state.isFetchingFolioDelete = false;
    },
    addBlock: (state, action) => {
      state.structure.push({
        ...action.payload,
        cols: action.payload.cols ?? 12,
      });
    },
    setBlocks: (state, action) => {
      state.structure = action.payload.map((blockId) =>
        state.structure.find(({id}) => blockId === id)
      );
    },
    setBlockWidth: (state, action) => {
      state.structure[action.payload.index].cols = action.payload.cols;
      state.structure[action.payload.index].is_content_dirty = 1;
      // state.structure[action.payload.index].props.background = {};
      // state.structure[action.payload.index].props.background = action.payload
      //   .background || {
      //   color: "hsl(360, 100%, 100%)",
      //   type: "color",
      // };
    },
    setTextContent: (state, action) => {
      state.structure[action.payload.index].content = {
        ...state.structure[action.payload.index].content,
        text: action.payload.text,
      };
      state.structure[action.payload.index].is_content_dirty = 1;
    },
    onAddAccordionItem: (state, action) => {
      const content = get(state, action.payload.pathToContent, []);
      state.structure[action.payload.blockIndex].content = {
        ...state.structure[action.payload.blockIndex].content,
        text: [
          ...content,
          ...(content.length > 2
            ? content.slice(-2)
            : [emptyPlaceholderText, emptyPlaceholderText]),
        ],
      };
      state.structure[action.payload.blockIndex].is_content_dirty = 1;
    },
    onRemoveAccordionItem: (state, action) => {
      state.structure[action.payload.blockIndex].content = {
        ...state.structure[action.payload.blockIndex].content,
        text: get(state, action.payload.pathToContent, []).filter(
          (_, idx) =>
            idx !== action.payload.indexToRemove &&
            idx !== action.payload.indexToRemove + 1
        ),
      };
      state.structure[action.payload.blockIndex].is_content_dirty = 1;
    },
    setBlockBoundaries: (state, action) => {
      state.structure[action.payload.index].props.blockBoundaries = action.payload.values;
      state.structure[action.payload.index].is_content_dirty = 1;
    },
    setElementIndents: (state, action) => {
      const path = action.payload.path;
      const indentName = action.payload.indentName;
      const values = action.payload.values;
      if (get(state, path)) {
        set(state, `${path}.${indentName}`, values);
      }
    },
    removeBlock: (state, action) => {
      state.structure = [
        ...state.structure.slice(0, action.payload),
        ...state.structure.slice(action.payload + 1),
      ];
    },
    copyBlock: (state, action) => {
      // state.structure = [...state.structure, state.structure[action.payload]];
      state.structure = [
        ...state.structure.slice(0, action.payload + 1),
        {...state.structure[action.payload], id: uuidv4()},
        ...state.structure.slice(action.payload + 1),
      ];
    },
    setBlockColor: (state, action) => {
      set(
        state,
        `structure[${action.payload.index}].props.background.src`,
        action.payload.color
      );
      set(
        state,
        `structure[${action.payload.index}].props.background.type`,
        action.payload.type
      );
      state.structure[action.payload.index].is_content_dirty = 1;
    },
    changeBlock: (state, action) => {
      state.structure[action.payload.index] = {
        ...state.structure[action.payload.index],
        layout: {...action.payload.layout.layout},
        layout_id: action.payload.layout.id,
        content: action.payload.content,
        is_content_dirty: action.payload.is_content_dirty || 0,
        compatibility_content_group: action.payload.layout.compatibility_content_group,
        props: {
          ...action.payload.layout.props,
          background:
            !action.payload.layout.props ||
            !action.payload.layout.props.background ||
            Array.isArray(action.payload.layout.props.background)
              ? {}
              : action.payload.layout.props.background,
        },
      };
    },
    changeToContactsBlock: function (state, action) {
      state.structure[action.payload.index] = {
        ...state.structure[action.payload.index],
        content: {...action.payload.layout.content},
      };
      state.structure[action.payload.index].is_content_dirty = 1;
    },
    changeLockedProps: (state, action) => {
      set(
        state,
        `structure[${action.payload.index}].is_lock`,
        !get(state, `structure[${action.payload.index}].is_lock`, false)
      );
    },
    setElementProps: (state, action) => {
      if (action.payload.fullUpdate) {
        set(state, action.payload.path, action.payload.props);
      } else {
        const props = get(state, action.payload.path);
        set(state, action.payload.path, {
          ...props,
          ...action.payload.props,
        });
      }
      if (action.payload.blockIndex !== undefined && action.payload.blockIndex !== -1) {
        state.structure[action.payload.blockIndex].is_content_dirty = 1;
      }
    },
    setHeroConfig: (state, action) => {
      state.hero.layout = JSON.parse(action.payload.layout);
      state.hero.props.avatarShape = action.payload.avatarShape;
      state.hero.props.avatarBorder = action.payload.avatarBorder;
      state.hero.props.height = action.payload.height;
      state.hero.layout_id = action.payload.layoutId;
    },
    clearFolio: (state) => {
      state.structure = [];
      state.hero = null;
      state.folioConfig = {};
    },
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setVCardData: (state, action) => {
      state.folioConfig.vcard = action.payload;
    },
    setStructure: (state, action) => {
      state.structure = action.payload;
    },
    startShareSending: (state) => {
      state.isSendingShareForm = true;
    },
    endShareSending: (state) => {
      state.isSendingShareForm = false;
    },
    setIfolioConfigMetategs: (state, action) => {
      state.folioConfig.keywords = action.payload.keywords;
      state.folioConfig.description = action.payload.description;
    },
    setIsQuillToolbarActive: (state, action) => {
      state.isQuillToolbarActive = action.payload;
    },
    setIsQuillCustomButtonToolbarExpanded: (state, action) => {
      state.isQuillCustomButtonToolbarExpanded = action.payload;
    },
  },
});

export const {
  setStructure,
  setFolioConfig,
  startPendingFolioDuplicate,
  endPendingFolioDuplicate,
  startPendingFolioDelete,
  endPendingFolioDelete,
  setIfolioConfigMetategs,
} = folioSlice.actions;

export const FolioActions = folioSlice.actions;
