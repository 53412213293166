import {createSlice} from "@reduxjs/toolkit";

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    layouts: {
      isFetching: false,
      data: {},
    },
    layoutGroups: {
      isFetching: false,
      data: [],
    },
    heroLayouts: {
      isFetching: false,
      data: [],
    },
    templates: {
      isFetching: false,
      data: [],
    },
  },
  reducers: {
    pendingLayoutGroups: (state) => {
      state.layoutGroups.isFetching = true;
    },
    fulfilledLayoutGroups: (state, action) => {
      state.layoutGroups.isFetching = false;
      state.layoutGroups.data = action.payload;
    },
    rejectedLayoutGroups: (state) => {
      state.layoutGroups.isFetching = false;
    },
    pendingLayouts: (state) => {
      state.layouts.isFetching = true;
    },
    fulfilledLayouts: (state, action) => {
      state.layouts.isFetching = false;
      state.layouts.data[action.payload.type] = action.payload.data;
    },
    rejectedLayouts: (state) => {
      state.layouts.isFetching = false;
    },
    pendingHeroLayouts: (state) => {
      state.heroLayouts.isFetching = true;
    },
    fulfilledHeroLayouts: (state, action) => {
      state.heroLayouts.isFetching = false;
      state.heroLayouts.data = action.payload;
    },
    rejectedHeroLayouts: (state) => {
      state.heroLayouts.isFetching = false;
    },
    pendingPresets: (state) => {
      state.templates.isFetching = true;
    },
    fulfilledPresets: (state, action) => {
      state.templates.isFetching = false;
      state.templates.data = action.payload;
    },
    rejectedPresets: (state) => {
      state.templates.isFetching = false;
    },
  },
});

export const ContentActions = contentSlice.actions;
