export const dateRangePickerClasses = `
  .react-daterange-picker-custom .react-daterange-picker__wrapper {
    display: none;
  }
  
  .react-daterange-picker-custom .react-daterange-picker__calendar {
    position: unset !important;
    height: auto !important;
  }
  
  .react-daterange-picker-custom .react-calendar {
    border: 0;
  }
  
  .react-daterange-picker-custom .react-calendar__navigation {
    background: rgba(197,217,232, 0.2);
  }
  
  .react-daterange-picker-custom .react-calendar__navigation button:enabled:hover,  
  .react-daterange-picker-custom .react-calendar__navigation button:enabled:focus {
    background-color: rgba(197,217,232, 0.6);
  }
  
  .react-daterange-picker-custom button.react-calendar__navigation__label {
    font-family: 'Montserrat-bold', 'Arial', sans-serif;
    color: #25bec8;
  }
  
  .react-daterange-picker-custom .react-calendar__navigation__arrow {
    color: #4a4a4a;
  }
  
  .react-daterange-picker-custom .react-calendar__tile {
    font-family: 'HelveticaNeue-light', Arial, sans-serif;
    color: #4a4a4a;
    font-size: 15px;
  }
  
  .react-daterange-picker-custom .react-calendar__navigation button:disabled {
    background-color: transparent;
    color: rgba(31,39,65, 0.4)
  }
  
  .react-daterange-picker-custom .react-calendar__month-view__weekdays__weekday {
    font-family: 'HelveticaNeue-light', Arial, sans-serif;
    color: #8dabc4;
    font-size: 10px;
    font-weight: normal;
    text-transform: none;
  }
  
  .react-daterange-picker-custom .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }
  
  .react-daterange-picker-custom .react-calendar__tile:disabled {
    background-color: transparent;
    color: #8dabc4;
  }

  .react-daterange-picker-custom .react-calendar__tile:enabled:hover,
  .react-daterange-picker-custom .react-calendar__tile:enabled:focus {
    background-color: rgba(197,217,232, 0.2);
  }

  .react-daterange-picker-custom .react-calendar__tile--now {
    background: #fd6864;
  }

  .react-daterange-picker-custom .react-calendar__tile--now:enabled:hover,
  .react-daterange-picker-custom .react-calendar__tile--now:enabled:focus {
    background: #fd6864;
  }

  .react-daterange-picker-custom .react-calendar__tile--hasActive {
    background: #25bec8;
    color: #ffffff;
  }

  .react-daterange-picker-custom .react-calendar__tile--hasActive:enabled:hover,
  .react-daterange-picker-custom .react-calendar__tile--hasActive:enabled:focus {
    background: #25bec8;
    color: #ffffff;
  }

  .react-daterange-picker-custom .react-calendar__tile--active {
    background: #069696;
    color: #ffffff;
  }

  .react-daterange-picker-custom .react-calendar__tile--active:enabled:hover,
  .react-daterange-picker-custom .react-calendar__tile--active:enabled:focus {
    background: #069696;
  }

  .react-daterange-picker-custom .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: rgba(197,217,232, 0.2);
  }  
`;
