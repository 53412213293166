import React, {memo} from "react";

export const IconBlockLayout = memo(() => (
  <svg viewBox="0 0 20 20">
    <path
      d="M20 9.333V20h-9.412V9.333H20zM8 14.667V20H0v-5.333h8zM8 0v12H0V0h8zm12
    0v6.667h-9.333V0H20z"
    />
  </svg>
));
