import React, {memo} from "react";

const Heroes3 = memo(() => (
  <svg viewBox="0 0 380 176">
    <rect fill="#defafa" width="380" height="176" rx="3.83" ry="3.83" />
    <circle fill="#069696" cx="111.89" cy="88" r="34.56" />
    <circle fill="#fff" cx="113.01" cy="83.58" r="6.92" />
    <path
      fill="#fff"
      d="M112.7,66.75A22.25,22.25,0,1,0,134.95,89,22.25,22.25,0,0,0,112.7,66.75ZM128,99.85c-.53-2.74-2.22-8.81-6.7-8.81-4,0-3.54,3.5-8.25,3.5s-4.25-3.5-8.25-3.5c-4.9,0-6.46,7.25-6.82,9.48a18.75,18.75,0,1,1,30-.67Z"
    />
    <rect fill="#069696" x="176.18" y="67.12" width="126.5" height="6.17" />
    <rect fill="#84d1d1" x="176.18" y="82.5" width="126.5" height="2.54" />
    <rect fill="#84d1d1" x="176.18" y="93.5" width="126.5" height="2.54" />
    <rect fill="#84d1d1" x="176.18" y="104.5" width="126.5" height="2.54" />
  </svg>
));

Heroes3.displayName = "Heroes3";

export default Heroes3;
