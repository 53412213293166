import React, {memo} from "react";

const AddMediaIcon = memo(() => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32">
      <g transform="translate(4.267 6.4)">
        <path d="M21.683 10.667L20.227 10.667 20.227 13.826 17.067 13.826 17.067 16.04 20.227 16.04 20.227 18.63 20.227 19.2 22.44 19.2 22.44 16.04 25.6 16.04 25.6 13.826 22.44 13.826 22.44 10.667zM14.494 5.876c0 1 .85 1.81 1.897 1.81 1.048 0 1.897-.81 1.897-1.81 0-.999-.85-1.81-1.897-1.81-1.047 0-1.897.81-1.897 1.81" />
        <path d="M15.705 16.98v-4.106h-1.246l-3.09-4.596-1.218 1.813 1.87 2.783h-1.13l-1.306-1.941-2.627-3.908-3.931 5.849h-.973V2.01H19.09v7.894h2.073V.903c0-.465-.394-.841-.88-.841H.881C.395.062 0 .438 0 .902V17.17c0 .465.395.841.882.841h17.69v-1.03h-2.867z" />
      </g>
    </svg>
  );
});

AddMediaIcon.displayName = "AddMediaIcon";

export default AddMediaIcon;
