import React, {memo} from "react";

const Heroes4 = memo(() => (
  <svg viewBox="0 0 380 176">
    <rect fill="#defafa" width="380" height="176" rx="3.83" ry="3.83" />
    <rect fill="#069696" x="91.74" y="55.73" width="204.52" height="9.97" />
    <rect fill="#84d1d1" x="91.74" y="80.59" width="204.52" height="4.11" />
    <rect fill="#84d1d1" x="91.74" y="98.38" width="204.52" height="4.11" />
    <rect fill="#84d1d1" x="91.74" y="116.16" width="204.52" height="4.11" />
  </svg>
));

Heroes4.displayName = "Heroes4";

export default Heroes4;
