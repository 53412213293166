import {fetchInstance} from "wrappers/axios";
import {api} from "config/api";
import {MediaActions} from "reducers/media";
import {toast} from "react-toastify";

export const getItemsQty = () => {
  const oneItemHeight = 185;
  const oneItemWidth = 175;
  // items in height
  let itemsInHeight = Math.floor((window.screen.height - 350) / oneItemHeight);
  itemsInHeight = (itemsInHeight >= 1 && itemsInHeight) || 1;
  // items in width
  const itemsInWidth = Math.floor((window.screen.width - 100) / oneItemWidth);
  const itemsInQuery = itemsInHeight * itemsInWidth;
  return (itemsInQuery <= 100 && itemsInQuery) || 100;
};

export const getMedia = (prs, isTemplate, isGlobal, account) => async (
  dispatch,
  getState
) => {
  const isMobile = window.innerWidth <= 768;
  const params = {...prs};
  const state = getState();
  const {isFirstOpenQuery, filters} = getState().media;
  params.affiliation = filters.leftMenuActiveItem;
  params.type = isMobile ? "all_without_documents" : filters.rightMenuActiveItem;
  params.limit = isMobile ? 20 : getItemsQty();
  params.user_id = state.folio.folioConfig.user?.id;
  params.account_id = state.user.profile?.default_account?.id;
  const isTemplate = state.app?.isTemplate;
  const userAccountId = state?.user?.accountId;
  if (isTemplate) {
    if (userAccountId) {
      params.account_id = userAccountId;
      // params.mode = "manager";
    } //else {
    // params.mode = "admin";
    // }
  }
  const url = api.getMedia;
  if (filters.search) {
    params["filter[0][column]"] = "name";
    params["filter[0][operator]"] = "like";
    params["filter[0][value]"] = `%${filters.search}%`;
  }
  dispatch(MediaActions.setAll([]));
  dispatch(MediaActions.setIsFetching(true));
  try {
    let response = await fetchInstance({
      method: "GET",
      url,
      params: {...params, order: "-id"},
    });
    if (
      isFirstOpenQuery &&
      params.affiliation === "my_uploads" &&
      response.data.data.length === 0
    ) {
      if (isTemplate && account?.has_org_media_library) {
        let leftMenuCurrentItem = "organisations";
        response = await fetchInstance({
          method: "GET",
          url,
          params: {...params, affiliation: leftMenuCurrentItem, order: "-id"},
        });
        dispatch(
          MediaActions.setMediaMenuCurrentTabs({
            left: leftMenuCurrentItem,
            right: "all_without_documents",
          })
        );
        dispatch(MediaActions.setIsFirstOpenQuery(false));
      }

      if (
        (!isTemplate && isGlobal && account?.has_ifolio_stock_library) ||
        (isTemplate && account?.has_ifolio_stock_library)
      ) {
        if (response.data.data.length === 0) {
          let leftMenuCurrentItem = "stocks";
          response = await fetchInstance({
            method: "GET",
            url,
            params: {...params, affiliation: leftMenuCurrentItem, order: "-id"},
          });
          dispatch(
            MediaActions.setMediaMenuCurrentTabs({
              left: leftMenuCurrentItem,
              right: "all_without_documents",
            })
          );
          dispatch(MediaActions.setIsFirstOpenQuery(false));
        }
      }
    }

    dispatch(MediaActions.setAll(response.data));
    return response.data;
  } catch (e) {
    dispatch(MediaActions.setAll([]));
  } finally {
    dispatch(MediaActions.setIsFetching(false));
  }
};

const uploadImage = async (params, onProgressBarCalback) => {
  if (params.file.size > 5242880) {
    toast.error("The file may not be greater than 5120 kilobytes.");
    return Promise.resolve;
  }
  let formData = new FormData();
  Object.keys(params).forEach((k) => {
    formData.append(k, params[k]);
  });

  try {
    const image = await fetchInstance({
      method: "POST",
      url: api.uploadMedia,
      headers: {"Content-Type": "multipart/form-data"},
      data: formData,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentage = (progressEvent.loaded / progressEvent.total) * 100;
          onProgressBarCalback(params.file.name, percentage.toFixed(0));
        }
      },
    });

    const {src} = image.data.data;
    onProgressBarCalback(params.file.name, 100, src);

    return Promise.resolve(image);
  } catch (e) {
    console.log(e);
  }
};

const getFullParams = ({isTemplate, isGlobal, accountId, userId, params}) => {
  if (isTemplate && !isGlobal && accountId) {
    params["account_id"] = accountId;
  } else if (isTemplate && isGlobal) {
    params["is_global"] = 1;
  } else if (userId) {
    params["user_id"] = userId;
  }

  return {...params};
};

const showFilesUploaded = (files) => {
  if (files) {
    for (const file of files) {
      if (file.status === 201 || file.status === 200) {
        const {name} = file.data.data;
        toast.info(`File ${name} was uploaded`);
      }
    }
  }
};

const getParams = (state) => {
  const isTemplate = state.app.isTemplate;
  const isGlobal = state.folio.folioConfig.is_global;
  const accountId = state?.user?.accountId;
  return {isTemplate, isGlobal, accountId};
};

export const uploadImages = ({
  images,
  affiliation,
  type = "all_without_documents",
  progressBarCalback = null,
  isContentEditor,
}) => async (dispatch, getState) => {
  const state = getState();
  const {isTemplate, isGlobal, accountId} = getParams(state);
  const limit = state.media.meta?.per_page;
  const userId = state.folio.folioConfig?.user?.id || state.user.profile.id;
  const uploadRequests = Object.entries(images).map((im) => {
    const params = {
      file: im[1],
      affiliation,
    };
    return uploadImage(
      getFullParams({
        isTemplate: isTemplate || isContentEditor,
        isGlobal,
        accountId,
        userId,
        params,
      }),
      progressBarCalback
    );
  });

  try {
    dispatch(MediaActions.setIsFetching(true));
    dispatch(MediaActions.setIsUploading(true));
    const files = await Promise.all(uploadRequests);

    showFilesUploaded(files);
    dispatch(getMedia({page: 1, limit, affiliation, type}));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(MediaActions.setIsFetching(false));
    dispatch(MediaActions.setIsUploading(false));
  }
};

export const uploadVideoByLink = ({
  linkName,
  linkValue,
  userId,
  isContentEditor = false,
}) => async (dispatch, getState) => {
  const {isTemplate, isGlobal, accountId} = getParams(getState());
  const limit = getState().media.meta?.per_page;
  const affiliation = getState().modal["link-to-video-modal"].props.from;
  const params = {
    name: linkName,
    link: linkValue,
    affiliation,
  };
  const fullParams = getFullParams({
    isTemplate: isTemplate || isContentEditor,
    isGlobal,
    accountId,
    userId,
    params,
  });

  let formData = new FormData();
  Object.keys(fullParams).forEach((key) => {
    formData.append(key, fullParams[key]);
  });

  dispatch(MediaActions.setIsLinkUploading(true));
  try {
    const file = await fetchInstance({
      method: "POST",
      url: api.uploadMedia,
      data: formData,
    });
    showFilesUploaded([file]);

    dispatch(getMedia({page: 1, limit, affiliation}));
  } finally {
    dispatch(MediaActions.setIsLinkUploading(false));
  }
};

export const updateMedia = () => async (dispatch, getState) => {
  dispatch(MediaActions.setIsFetching(true));
  try {
    const response = await fetchInstance({
      method: "PUT",
      url: api.getMedia,
    });
    dispatch(MediaActions.setAll(response.data));
  } finally {
    dispatch(MediaActions.setIsFetching(false));
  }
};

export const deleteMedia = ({mediaId, params, page}) => async (dispatch, getState) => {
  dispatch(MediaActions.setIsFetching(true));
  try {
    await fetchInstance({
      method: "DELETE",
      url: api.deleteMedia(mediaId),
    });
    dispatch(getMedia({...params, page}));
  } finally {
    dispatch(MediaActions.setIsFetching(false));
  }
};

export const getMediaMetaByHash = async (src) => {
  try {
    //eslint-disable-next-line
    const file = src.replace(/^.*[\\\/]/, "");
    const fileName = file.split(".")[0];
    if (fileName) {
      const response = await fetchInstance({
        method: "GET",
        url: api.getMediaMetaByHash(fileName),
      });
      return response?.data?.data?.meta;
    }
    return undefined;
  } catch (e) {
    return undefined;
  }
};
