import React, {memo} from "react";

export const IconBlockDuplicate = memo(() => (
  <svg viewBox="0 0 20 20">
    <path
      d="M1.449 19.167c-.34 0-.616-.276-.616-.616V3.782c0-.34.276-.615.616-.615h1.23c.34 0
    .616.275.616.615v12.923h12.923c.34 0 .615.276.615.616v1.23c0 .34-.275.616-.615.616H1.448zM19.167 1.444V14.89c0
    .337-.274.611-.611.611H5.11c-.337 0-.611-.274-.611-.611V1.444c0-.337.274-.61.611-.61h13.445c.337 0
    .61.273.61.61zM16.5 6.834h-3.333V3.5H10.5v3.333H7.167V9.5H10.5v3.333h2.667V9.5H16.5V6.833z"
    />
  </svg>
));
