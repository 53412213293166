import React, {memo, useCallback} from "react";
import styled from "styled-components";
import {Button} from "../../../../components/Button";
import {updateUser} from "../../../../actions/user";
import {useDispatch, useSelector} from "react-redux";
import {publishUnpublishFolio} from "../../../../actions/folio";
import {AppActions} from "../../../../reducers/app";
import {setFolioConfig} from "../../../../reducers/folio";
import {theme} from "../../../../assets/theme/theme";
import {UserActions} from "../../../../reducers/user";

const SkipModal = memo(({setPaused, setSkipped}) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.profile.id);
  const folioId = useSelector((state) => state.app.folioId);

  const handleSkip = useCallback(async () => {
    dispatch(UserActions.pendingSkipTutorial());
    setSkipped(true);
    const response = await publishUnpublishFolio({
      id: folioId,
      is_published: false,
    });
    await dispatch(setFolioConfig(response.data.data));
    await dispatch(
      updateUser({show_tutorial: false, show_mobile_tutorial: false}, userId)
    );
    await dispatch(AppActions.setFolioPublishStatus("process"));
    dispatch(UserActions.fulfilledSkipTutorial());
  }, [dispatch, folioId, setSkipped, userId]);

  const handleContinue = useCallback(() => {
    setPaused(false);
  }, [setPaused]);

  return (
    <SkipModalWrapper>
      <Title>Are you sure you want to skip?</Title>
      <Text>
        You can repeat this walk-through or view helpful videos at any time in the “Tips &
        Tutorials” tab in the dashboard.
      </Text>
      <ButtonsContainer>
        <Button
          onClick={() => handleContinue()}
          options={{title: "CONTINUE WALKTHROUGH"}}
          theme={{
            padding: "10px",
            borderColorChange: theme.colors.accent,
          }}
        />
        <Button
          onClick={() => handleSkip()}
          options={{title: "SKIP WALKTHROUGH"}}
          theme={{
            padding: "10px",
            color: "transparent",
            border: 2,
            borderColor: "#1f2741",
            borderColorChange: "#1f2741",
            textColor: "#1f2741",
            textColorChange: "#1f2741",
          }}
        />
      </ButtonsContainer>
    </SkipModalWrapper>
  );
});

SkipModal.displayName = "SkipModal";

const SkipModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  border-radius: 8px;
  padding: 24px 46px 32px 46px;
  box-shadow: 0 1px 4px 0 rgba(44, 64, 90, 0.21), inset 0 -1px 0 0 #7e95a7;
  border: solid 1px rgba(183, 210, 229, 0.8);
  max-width: 400px;
`;
const Title = styled.div`
  margin-bottom: 16px;
  font-size: 19px;
  line-height: 1.84;
  letter-spacing: -0.16px;
  color: #1f2741;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratBold};
`;
const Text = styled.div`
  font-size: 17px;
  line-height: 1.59;
  letter-spacing: -0.14px;
  text-align: center;
  color: #1f2741;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratNormal};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  margin-top: 32px;
`;

export default SkipModal;
