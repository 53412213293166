import {createGlobalStyle} from "styled-components";
import {commonClasses} from "assets/global/common";
import {modalClasses} from "assets/global/modal";
import {scrollClasses} from "assets/global/scroll";
import {tooltipClasses} from "assets/global/tooltip";
import {sliderClasses} from "assets/global/slider";
import {masonryStyles} from "assets/global/masonry";
import {quillClasses} from "assets/global/quill";
import {dateRangePickerClasses} from "./dateRangePicker";
import {scrollbarClasses} from "./scrollbar";
import {printClasses} from "./print";
import {toastifyClasses} from "./toastify";

const GlobalStyle = createGlobalStyle`
  ${commonClasses}
  ${modalClasses}
  ${scrollClasses}
  ${tooltipClasses}
  ${sliderClasses}
  ${masonryStyles}
  ${quillClasses}
  ${scrollbarClasses}
  ${printClasses}
  ${toastifyClasses}
  ${dateRangePickerClasses}
`;

export default GlobalStyle;
