import React, {memo} from "react";

export const AvatarIcon = memo(() => (
  <svg x="0px" y="0px" viewBox="0 0 50 50">
    <g transform="translate(20.470588, 20.470588)">
      <path
        d="M4.26-18.7c-12.74,0-23.03,10.28-23.03,23c0,6.39,2.61,12.12,6.81,16.31l0,0
		c1.59,1.53,3.33,2.86,5.32,3.94l0,0c0.31,0.15,0.67,0.36,0.97,0.51c0,0,0,0,0.05,0c0.31,0.15,0.67,0.31,0.97,0.46
		c0,0,0.05,0,0.05,0.05c0.31,0.15,0.67,0.26,0.97,0.36c0.05,0,0.1,0.05,0.15,0.05c0.31,0.1,0.61,0.2,0.92,0.31
		c0.1,0,0.15,0.05,0.26,0.05c0.31,0.1,0.56,0.15,0.87,0.26c0.1,0.05,0.2,0.05,0.31,0.05c0.26,0.05,0.51,0.15,0.82,0.2
		c0.15,0.05,0.26,0.05,0.41,0.05c0.26,0.05,0.46,0.1,0.72,0.15c0.15,0,0.31,0.05,0.46,0.05c0.2,0.05,0.46,0.05,0.67,0.1
		c0.2,0,0.36,0.05,0.56,0.05s0.41,0.05,0.61,0.05c0.41,0,0.77,0.05,1.18,0.05c0.41,0,0.82,0,1.23-0.05c0.1,0,0.26,0,0.36-0.05
		c0.26,0,0.56-0.05,0.82-0.05c0.15,0,0.26-0.05,0.41-0.05c0.26-0.05,0.51-0.05,0.77-0.1c0.15,0,0.26-0.05,0.36-0.05
		c0.26-0.05,0.51-0.1,0.77-0.15c0.1,0,0.26-0.05,0.36-0.1c0.26-0.05,0.51-0.1,0.77-0.2c0.1-0.05,0.2-0.05,0.31-0.1
		c0.26-0.05,0.51-0.15,0.82-0.26c0.1-0.05,0.2-0.05,0.31-0.1c0.26-0.1,0.56-0.2,0.82-0.31c0.1-0.05,0.15-0.05,0.26-0.1
		c0.31-0.1,0.56-0.2,0.82-0.36c0.05-0.05,0.15-0.05,0.2-0.1c0.31-0.15,0.56-0.26,0.87-0.41c0.05-0.05,0.1-0.05,0.15-0.1
		c0.31-0.15,0.56-0.31,0.87-0.46c0.05,0,0.1-0.05,0.1-0.05c0.31-0.15,0.56-0.36,0.87-0.51c0.05,0,0.05-0.05,0.1-0.05
		c0.31-0.2,0.56-0.36,0.87-0.56l0.05-0.05c0.31-0.2,0.56-0.41,0.87-0.66c0,0,0,0,0.05-0.05c0.31-0.2,0.56-0.46,0.87-0.72l0,0
		c0.31-0.26,0.56-0.51,0.82-0.77l0,0c0.26-0.26,0.56-0.51,0.82-0.82l0,0l0,0c3.89-3.99,6.29-9.56,6.29-15.69
		C27.29-8.42,17-18.7,4.26-18.7z M20.48,15.04c-0.31-4.45-3.58-8.18-7.98-9.05c-0.1,0-0.2-0.05-0.31-0.05
		c-2,2.1-4.81,3.37-7.93,3.37c-3.07,0-5.83-1.28-7.83-3.27c-4.35,0.97-7.52,4.75-7.78,9.15c0,0.2-0.05,0.46-0.05,0.66
		c-2.35-3.22-3.79-7.26-3.79-11.55c0-10.74,8.7-19.43,19.45-19.43S23.71-6.43,23.71,4.31C23.71,8.24,22.53,11.92,20.48,15.04z"
      />
    </g>
    <circle className="st0" cx="25" cy="18.73" r="7.27" />
  </svg>
));

AvatarIcon.displayName = "AvatarIcon";

export const AvatarIconRect = memo(() => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 87 87">
      <g>
        <g transform="translate(-697.000000, -148.000000)">
          <g transform="translate(697.000000, 148.000000)">
            <rect
              x="2"
              y="2"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DEFAFA"
              stroke="#FFFFFF"
              strokeWidth="4"
              width="83"
              height="83"
            />
            <g transform="translate(20.470588, 20.470588)">
              <path
                fill={"#069696"}
                d="M23,0C10.3,0,0,10.3,0,23c0,6.4,2.6,12.1,6.8,16.3l0,0c1.6,1.5,3.3,2.9,5.3,3.9l0,0
					c0.3,0.2,0.7,0.4,1,0.5c0,0,0,0,0.1,0c0.3,0.2,0.7,0.3,1,0.5c0,0,0.1,0,0.1,0.1c0.3,0.2,0.7,0.3,1,0.4c0.1,0,0.1,0.1,0.2,0.1
					c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.2
					c0.2,0.1,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.1,0.7,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4,0.1,0.6,0.1
					s0.4,0.1,0.6,0.1c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.2-0.1c0.1,0,0.3,0,0.4-0.1c0.3,0,0.6-0.1,0.8-0.1c0.2,0,0.3-0.1,0.4-0.1
					c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2
					c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.2,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1
					c0.3-0.1,0.6-0.2,0.8-0.4c0.1-0.1,0.2-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.4c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.5
					c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,0.9-0.6l0.1-0.1
					c0.3-0.2,0.6-0.4,0.9-0.7c0,0,0,0,0.1-0.1c0.3-0.2,0.6-0.5,0.9-0.7l0,0c0.3-0.3,0.6-0.5,0.8-0.8l0,0c0.3-0.3,0.6-0.5,0.8-0.8
					l0,0l0,0c3.9-4,6.3-9.6,6.3-15.7C46.1,10.3,35.8,0,23,0z M39.3,33.7c-0.3-4.4-3.6-8.2-8-9c-0.1,0-0.2-0.1-0.3-0.1
					C29,26.7,26.2,28,23,28c-3.1,0-5.8-1.3-7.8-3.3c-4.3,1-7.5,4.8-7.8,9.2c0,0.2-0.1,0.5-0.1,0.7C5,31.3,3.6,27.3,3.6,23
					C3.6,12.3,12.3,3.6,23,3.6S42.5,12.3,42.5,23C42.5,26.9,41.3,30.6,39.3,33.7z"
              />
              <path
                fill={"#069696"}
                d="M27.3,22.8C27.3,22.8,27.3,22.8,27.3,22.8c0.2-0.2,0.3-0.3,0.4-0.4l0.1-0.1
					c0.4-0.3,0.7-0.7,1-1.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3s0.1-0.2,0.2-0.3
					s0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.5
					c0-0.1,0.1-0.2,0.1-0.3C30,18.1,30,18,30,17.8c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7c0-4.1-3.3-7.4-7.4-7.4s-7.4,3.3-7.4,7.4
					c0,0.3,0,0.5,0.1,0.7c0,0.1,0,0.2,0.1,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.1,0.5
					c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.6C16,19.9,16,20,16,20c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
					c0.1,0.1,0.1,0.2,0.2,0.3s0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.4,0.6,0.7,1,1.1l0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3
					c0.1,0,0.1,0.1,0.1,0.1c0.6,0.5,1.2,0.8,1.9,1l0,0c0.8,0.3,1.6,0.4,2.4,0.4c0.9,0,1.7-0.2,2.5-0.5l0,0
					C26.1,23.6,26.7,23.3,27.3,22.8z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});

AvatarIconRect.displayName = "AvatarIconRect";

export const AvatarIconRectVideo = memo(() => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 87 87">
      <g>
        <g transform="translate(-697.000000, -148.000000)">
          <g transform="translate(697.000000, 148.000000)">
            <rect
              x="2"
              y="18"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#DEFAFA"
              stroke="#FFFFFF"
              strokeWidth="4"
              width="83"
              height="51"
            />
            <g transform="translate(20.470588, 20.470588)">
              <path
                fill={"#069696"}
                d="M23,0C10.3,0,0,10.3,0,23c0,6.4,2.6,12.1,6.8,16.3l0,0c1.6,1.5,3.3,2.9,5.3,3.9l0,0
					c0.3,0.2,0.7,0.4,1,0.5c0,0,0,0,0.1,0c0.3,0.2,0.7,0.3,1,0.5c0,0,0.1,0,0.1,0.1c0.3,0.2,0.7,0.3,1,0.4c0.1,0,0.1,0.1,0.2,0.1
					c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.2
					c0.2,0.1,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.1,0.7,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4,0.1,0.6,0.1
					s0.4,0.1,0.6,0.1c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.2-0.1c0.1,0,0.3,0,0.4-0.1c0.3,0,0.6-0.1,0.8-0.1c0.2,0,0.3-0.1,0.4-0.1
					c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2
					c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.2,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1
					c0.3-0.1,0.6-0.2,0.8-0.4c0.1-0.1,0.2-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.4c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.5
					c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,0.9-0.6l0.1-0.1
					c0.3-0.2,0.6-0.4,0.9-0.7c0,0,0,0,0.1-0.1c0.3-0.2,0.6-0.5,0.9-0.7l0,0c0.3-0.3,0.6-0.5,0.8-0.8l0,0c0.3-0.3,0.6-0.5,0.8-0.8
					l0,0l0,0c3.9-4,6.3-9.6,6.3-15.7C46.1,10.3,35.8,0,23,0z M39.3,33.7c-0.3-4.4-3.6-8.2-8-9c-0.1,0-0.2-0.1-0.3-0.1
					C29,26.7,26.2,28,23,28c-3.1,0-5.8-1.3-7.8-3.3c-4.3,1-7.5,4.8-7.8,9.2c0,0.2-0.1,0.5-0.1,0.7C5,31.3,3.6,27.3,3.6,23
					C3.6,12.3,12.3,3.6,23,3.6S42.5,12.3,42.5,23C42.5,26.9,41.3,30.6,39.3,33.7z"
              />
              <path
                fill={"#069696"}
                d="M27.3,22.8C27.3,22.8,27.3,22.8,27.3,22.8c0.2-0.2,0.3-0.3,0.4-0.4l0.1-0.1
					c0.4-0.3,0.7-0.7,1-1.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3s0.1-0.2,0.2-0.3
					s0.1-0.2,0.2-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.5
					c0-0.1,0.1-0.2,0.1-0.3C30,18.1,30,18,30,17.8c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7c0-4.1-3.3-7.4-7.4-7.4s-7.4,3.3-7.4,7.4
					c0,0.3,0,0.5,0.1,0.7c0,0.1,0,0.2,0.1,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.1,0.5
					c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.2,0.6C16,19.9,16,20,16,20c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3
					c0.1,0.1,0.1,0.2,0.2,0.3s0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.4,0.6,0.7,1,1.1l0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3
					c0.1,0,0.1,0.1,0.1,0.1c0.6,0.5,1.2,0.8,1.9,1l0,0c0.8,0.3,1.6,0.4,2.4,0.4c0.9,0,1.7-0.2,2.5-0.5l0,0
					C26.1,23.6,26.7,23.3,27.3,22.8z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
});

AvatarIconRectVideo.displayName = "AvatarIconRectVideo";

export const AvatarIconCircle = memo(() => (
  <svg x="0px" y="0px" viewBox="0 0 87 87">
    <g>
      <g transform="translate(-824.000000, -148.000000)">
        <g transform="translate(824.000000, 148.000000)">
          <circle
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#DEFAFA"
            stroke="#FFFFFF"
            strokeWidth="4"
            cx="43.5"
            cy="43.5"
            r="41.5"
          />
          <g transform="translate(20.470588, 20.470588)">
            <path
              fill="#069696"
              d="M23,0C10.3,0,0,10.3,0,23c0,6.4,2.6,12.1,6.8,16.3l0,0c1.6,1.5,3.3,2.9,5.3,3.9l0,0
					c0.3,0.2,0.7,0.4,1,0.5c0,0,0,0,0.1,0c0.3,0.2,0.7,0.3,1,0.5c0,0,0.1,0,0.1,0.1c0.3,0.2,0.7,0.3,1,0.4c0.1,0,0.1,0.1,0.2,0.1
					c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.9,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0.1,0.5,0.2,0.8,0.2
					c0.2,0.1,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.1,0.7,0.2c0.2,0,0.3,0.1,0.5,0.1c0.2,0.1,0.5,0.1,0.7,0.1c0.2,0,0.4,0.1,0.6,0.1
					s0.4,0.1,0.6,0.1c0.4,0,0.8,0.1,1.2,0.1c0.4,0,0.8,0,1.2-0.1c0.1,0,0.3,0,0.4-0.1c0.3,0,0.6-0.1,0.8-0.1c0.2,0,0.3-0.1,0.4-0.1
					c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.1,0,0.3-0.1,0.4-0.1c0.3-0.1,0.5-0.1,0.8-0.2
					c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.3-0.1,0.6-0.2,0.8-0.3c0.1-0.1,0.2-0.1,0.3-0.1
					c0.3-0.1,0.6-0.2,0.8-0.4c0.1-0.1,0.2-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.4c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.3,0.9-0.5
					c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,0.9-0.5c0.1,0,0.1-0.1,0.1-0.1c0.3-0.2,0.6-0.4,0.9-0.6l0.1-0.1
					c0.3-0.2,0.6-0.4,0.9-0.7c0,0,0,0,0.1-0.1c0.3-0.2,0.6-0.5,0.9-0.7l0,0c0.3-0.3,0.6-0.5,0.8-0.8l0,0c0.3-0.3,0.6-0.5,0.8-0.8
					l0,0l0,0c3.9-4,6.3-9.6,6.3-15.7C46.1,10.3,35.8,0,23,0z M39.3,33.7c-0.3-4.4-3.6-8.2-8-9c-0.1,0-0.2-0.1-0.3-0.1
					C29,26.7,26.2,28,23,28c-3.1,0-5.8-1.3-7.8-3.3c-4.3,1-7.5,4.8-7.8,9.2c0,0.2-0.1,0.5-0.1,0.7C5,31.3,3.6,27.3,3.6,23
					C3.6,12.3,12.3,3.6,23,3.6S42.5,12.3,42.5,23C42.5,26.9,41.3,30.6,39.3,33.7z"
            />
            <path
              fill="#069696"
              d="M27.9,22.8C28,22.8,28,22.8,27.9,22.8c0.2-0.2,0.3-0.3,0.4-0.4l0.1-0.1
					c0.4-0.3,0.7-0.7,1-1.1c0.1-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.3s0.1-0.2,0.2-0.3s0.1-0.2,0.2-0.3s0.1-0.2,0.2-0.4
					c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.2,0.1-0.3
					c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.2c0-0.2,0.1-0.5,0.1-0.7c0-4.1-3.3-7.4-7.4-7.4S16,12.9,16,17c0,0.3,0,0.5,0.1,0.7
					c0,0.1,0,0.2,0.1,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.3c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.2,0.1,0.2
					c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.1,0.1,0.2,0.1,0.2c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.3
					s0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.4,0.6,0.7,1,1.1l0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0,0.1,0.1,0.1,0.1
					c0.6,0.5,1.2,0.8,1.9,1l0,0c0.8,0.3,1.6,0.4,2.4,0.4c0.9,0,1.7-0.2,2.5-0.5l0,0C26.7,23.6,27.4,23.3,27.9,22.8z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
));

AvatarIconCircle.displayName = "AvatarIconCircle";
