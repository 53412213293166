import {fontSizesClass} from "components/TextEditorQuill/consts";
import {bulletTypes} from "../../config/consts";

const turntableColumns = () => {
  let styles = "";
  [4, 6, 8, 12].forEach((cols) => {
    // styles += `.static-turntable-${cols}{width: calc(${cols * (100 / 12)}% - 16px);}`;
    styles += `.static-turntable-${cols}{width: calc(${cols * (100 / 12)}%);}`;
    // styles += `.static-turntable-${cols}:last-child{width: calc(${cols * (100 / 12)}%);}`;
  });
  return styles;
};

/*

 */

let customBullets = ``;
Object.keys(bulletTypes).forEach((type) => {
  customBullets += `
    li[custom_bullet="${type}"] {
      &:before {
        content: url("${bulletTypes[type]}") !important;
      }
    }
  `;
});

export const commonClasses = `
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto-regular', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  
  * {
    box-sizing: border-box;

  }
  
  .highcharts-axis-labels {
    font-family: 'Montserrat-medium', 'Arial-regular', sans-serif;
  }
  
  .grid-sortable-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .card-number.StripeElement {
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    border-bottom: 1px solid #ffffff;
    padding: 10px 137px 10px 14px;
    background: #ffffff;
    @media all and (max-width: 768px) {
      font-size: 16px;
      padding: 14px 130px 14px 14px;
    }
  }

  .card-cvc {
    display:block;
    width: 100%;
    border-radius: 0 0 4px 0;
    border: 1px solid #c5d9e8;
    font-size: 14px;
    padding: 10px 14px;
    background: #fff;
    @media all and (max-width: 768px) {
      font-size: 16px;
      padding: 14px 14px;
    } 
  }

  .card-exp.StripeElement {
      display:block;
      width: 100%;
      border-radius: 0 0 0 4px;
      border: 1px solid #c5d9e8;
      font-size: 14px;
      padding: 10px 14px;
      background: #ffffff;    
      border-right: 1px solid #ffffff;  
      @media all and (max-width: 768px) {
        font-size: 16px;
        padding: 14px 14px;
      }
    }

  .StripeElement {
    border: 1px solid #c5d9e8;
  }

  .card-error {
    border: 1px solid #fd6864;
  } 
 
  .card-number.card-error {
    border: 1px solid #fd6864;
  }
  
  .card-exp.card-error {
    border: 1px solid #fd6864;
  }
 
  .ghost {
    background: #eee;
    border: 1px dashed rgba(0,0,0,.2);
    border-radius: 3px;
  }
  
  .chosen {
    opacity: 1 !important;
  }
  
  .ghost * {
    opacity: 0;
  }
  
  ${fontSizesClass}
  
  @media all and (max-width: 768px) {
    .analytics-slider .flickity-enabled {
      height: 100%;
    }
    
    .analytics-slider.flickity-enabled .flickity-viewport {
      height: 100% !important;
    }
  }
  
  // .static-turntable {
  //   margin-bottom: 0;
  //   margin-right: 16px;
  // }
  // .static-turntable:last-child {
  //   margin-right: 0;
  // }
  .static-turntable-adaptive-first-child {
    margin: 0;
    width: 100%;
  }
  
  .static-turntable-widget-wrapper {
    > div {
      margin-bottom: 16px;
    }
    
    > div:last-child {
      margin-bottom: 0;
    }
  }
 
  
  // .static-turntable-adaptive:last-child {
  //   margin-bottom: 0;
  // }
  ${turntableColumns()}
  
  a {
    padding-left: 0 !important;
    span:not([class^="ql-bullet-"]) {
      padding-left: 0 !important;
    }
  }
  
  ${customBullets}
  
  .navbar-hidden {
    visibility: hidden !important;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
  }
`;
