export const scrollClasses = `
.ScrollbarsCustom-Content {
  height: 100%;
}

.ScrollbarsCustom-TrackY {
  width: 5px !important;
}

.ScrollbarsCustom-TrackX {
  height: 5px !important;
}

.ScrollbarsCustom-Wrapper {
  right: 4px !important;
}

.ifolio_scrollbar_white .ScrollbarsCustom-TrackX {
    background: rgba(255,255,255,.1) !important;
}

.ifolio_scrollbar_white .ScrollbarsCustom-ThumbX {
    background: rgba(255,255,255,.4) !important;
}

.ifolio_scrollbar_white .ScrollbarsCustom-TrackY {
    background: rgba(255,255,255,.1) !important;
}

.ifolio_scrollbar_white .ScrollbarsCustom-ThumbY {
    background: rgba(255,255,255,.4) !important;
}

@media screen and (max-width: 675px) {
  .ScrollbarsCustom-Wrapper {
    right: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .ScrollbarsCustom {
    width: 100% !important;
    height: 100% !important;
  }
}
`;
