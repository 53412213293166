import {createSlice} from "@reduxjs/toolkit";

export const geoSlice = createSlice({
  name: "geo",
  initialState: {
    states: [],
    countries: [],
  },
  reducers: {
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const GeoActions = geoSlice.actions;
