import React, {lazy} from "react";
const FolioPage = lazy(() => import("pages/Folio"));
const Constructor = lazy(() => import("pages/Constructor"));
const StatisticsPage = lazy(() => import("pages/Statistics"));
const SharedDetail = lazy(() => import("pages/Statistics/SharedDetail"));
const SignIn = lazy(() => import("pages/SignIn"));
const Register = lazy(() => import("pages/Register"));
const Forgot = lazy(() => import("pages/Forgot"));
// const Renewal = lazy(() => import("pages/Renewal"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const UpdatePassword = lazy(() => import("pages/UpdatePassword"));
const PublishedPage = lazy(() => import("pages/Published"));
const TemplatePage = lazy(() => import("pages/Template"));
const LayoutEditor = lazy(() => import("pages/LayoutEditor"));
const PaymentPage = lazy(() => import("pages/Payment"));
const PaymentPageSuccess = lazy(() => import("pages/Payment/PaymentSuccess"));
const PaymentPageFailure = lazy(() => import("pages/Payment/PaymentFailure"));
const Pay = lazy(() => import("pages/Pay/PaymentPage"));
const DemoPay = lazy(() => import("pages/DemoPay/PaymentPage"));
const Profile = lazy(() => import("pages/Profile/Profile"));
const ContentEditor = lazy(() => import("pages/ContentEditor"));

export const folioEditPrefix = "/edit";

export const PRIVATE_ROUTES = {
  CONSTRUCTOR: {
    path: "/ifolio/constructor",
    component: Constructor,
  },
  STATISTICS: {
    path: "/ifolio/statistics/:folioId",
    component: StatisticsPage,
  },
  STATISTICS_SUMMARY: {
    path: "/ifolio/statistics/:folioId/summary",
    component: StatisticsPage,
  },
  STATISTICS_SOCIAL: {
    path: "/ifolio/statistics/:folioId/social",
    component: StatisticsPage,
  },
  STATISTICS_VIDEO_VIEWS: {
    path: "/ifolio/statistics/:folioId/video_views",
    component: StatisticsPage,
  },
  STATISTICS_CLICKS_ON_LINKS: {
    path: "/ifolio/statistics/:folioId/clicks_on_links",
    component: StatisticsPage,
  },
  STATISTICS_GEOGRAPHY: {
    path: "/ifolio/statistics/:folioId/geography",
    component: StatisticsPage,
  },
  STATISTICS_COMPANIES: {
    path: "/ifolio/statistics/:folioId/companies",
    component: StatisticsPage,
  },
  STATISTICS_SCROLLMAP: {
    path: "/ifolio/statistics/:folioId/scrollmap",
    component: StatisticsPage,
  },
  STATISTICS_SHARED_STATS: {
    path: "/ifolio/statistics/:folioId/shared_stats",
    component: StatisticsPage,
  },
  STATISTICS_SHARED_DETAILS: {
    path: "/ifolio/statistics/:folioId/shared_detail/:track",
    component: SharedDetail,
  },
  STATISTICS_LEADER_BOARD: {
    path: "/ifolio/statistics/:folioId/leader_board",
    component: StatisticsPage,
  },
  FOLIO: {
    path: "/ifolio/select",
    component: FolioPage,
  },
  FOLIO_EDIT_PREVIEW: {
    path: folioEditPrefix + "/:folioId/preview",
    component: () => <FolioPage isEdit={false} />,
  },
  FOLIO_EDIT: {
    path: folioEditPrefix + "/:folioId",
    component: () => <FolioPage isEdit={true} />,
  },
  FOLIO_LAYOUTS_EDIT: {
    path: "/layouts",
    component: LayoutEditor,
  },
  FOLIO_PAYMENT: {
    path: "/ifolio/payment",
    component: PaymentPage,
  },
  FOLIO_PAYMENT_SUCCESS: {
    path: "/ifolio/payment/success",
    component: PaymentPageSuccess,
  },
  FOLIO_PAYMENT_FAILURE: {
    path: "/ifolio/payment/fail",
    component: PaymentPageFailure,
  },

  UPDATE_PASSWORD: {
    path: "/ifolio/update-password",
    component: UpdatePassword,
  },

  PROFILE: {
    path: "/first-time-user-experience",
    component: Profile,
  },
};

export const PUBLIC_ROUTES = {
  AUTH: {
    path: "/signin",
    component: SignIn,
  },
  REGISTER: {
    path: "/ifolio/register",
    component: Register,
  },
  FORGOT: {
    path: "/ifolio/forgot",
    component: Forgot,
  },
  // RENEWAL: {
  //   path: "/ifolio/renewal",
  //   component: Renewal,
  // },
  RESET_PASSWORD: {
    path: "/ifolio/forgot/:token",
    component: ResetPassword,
  },
  PAYMENT: {
    path: "/paypage",
    component: Pay,
  },

  DEMO_PAYMENT: {
    path: "/single-pro-paypage",
    component: DemoPay,
  },
};

export const COMMON_ROUTES = {
  PUBLISHED_FOLIO: {
    path: "/ifolio/publishedBySlug/:slug",
    component: PublishedPage,
  },
  PUBLISHED_FOLIO_BY_ID: {
    path: "/ifolio/publishedById/:slug",
    component: PublishedPage,
  },
  TEMPLATES_PREVIEW: {
    path: "/template/:templateId/preview",
    component: () => <TemplatePage isEdit={false} />,
  },
  TEMPLATES: {
    path: "/template/:templateId",
    component: () => <TemplatePage isEdit={true} />,
  },
  CONTENT_EDITOR: {
    path: "/content-editor/:templateId",
    component: ContentEditor,
  },
};
