import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useOutsideClick} from "hooks/useOutsideClick";
import {RoundedButton} from "components/Button";
import {CSSTransition} from "react-transition-group";
import Buttons from "components/Buttons";
import {Desktop, Mobile} from "utils/mediaQueries";
import {useKeyClick} from "hooks/useKeyClick";
import Scrollbar from "react-scrollbars-custom";

const Modal = memo(
  ({
    title,
    children,
    onClose,
    type,
    timeout,
    icon,
    footer,
    onSave,
    controls,
    customHeader,
    noFooter,
    renderButtons,
    width,
    overflow,
    mobileHeader,
    bodyScroll,
    theme = {},
    noBodyPadding,
    needScroll,
    disableHandleHide,
    saveButtonDisabled,
    footerJustifyContent,
  }) => {
    const modalRef = useRef();
    const timer = useRef();

    const [isOpen, setOpen] = useState(false);

    const {padding, backgroundColor, zIndex} = theme;

    const handleClose = useCallback(() => {
      if (!disableHandleHide) {
        setOpen(false);
        document.body.removeAttribute("style");
        timer.current = setTimeout(() => {
          document.body.removeAttribute("style");
          onClose && onClose();
        }, timeout);
      }
    }, [disableHandleHide, onClose, timeout]);

    useKeyClick(handleClose, 27);

    useEffect(() => {
      timer.current = setTimeout(() => {
        document.body.style.overflow = "hidden";
        setOpen(true);
      }, timeout);
      return () => {
        clearTimeout(timer.current);
        document.body.removeAttribute("style");
      };
    }, [timeout]);

    const ref = useOutsideClick((event) => {
      if (modalRef.current === event.target) {
        handleClose();
      }
    });

    const HeaderIcon = useMemo(() => icon, [icon]);

    const buttons = useMemo(
      () => [
        {
          title: "cancel",
          action: handleClose,
          theme: {
            color: "#FD6864",
            colorChange: "#b14846",
          },
        },
        {
          title: "save",
          action: onSave,
          theme: {
            color: "#25BEC8",
            colorChange: "#19858c",
          },
          disabled: saveButtonDisabled,
        },
      ],
      [handleClose, onSave, saveButtonDisabled]
    );

    return (
      <CSSTransition
        classNames={type}
        in={isOpen}
        timeout={timeout}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <StyledModal type={type} ref={modalRef} zIndex={zIndex}>
          <Content
            type={type}
            ref={ref}
            width={width}
            padding={padding}
            backgroundColor={backgroundColor}
            bodyScroll={bodyScroll}
          >
            <Desktop>
              {customHeader ? (
                customHeader
              ) : (
                <Header type={type}>
                  <Heading>
                    {HeaderIcon && (
                      <IconWrapper>
                        <HeaderIcon />
                      </IconWrapper>
                    )}
                    {title && <Title type={type}>{title}</Title>}
                  </Heading>
                  {type !== "CENTER_SCREEN" ? (
                    controls ? (
                      <Controls>{controls}</Controls>
                    ) : (
                      <Controls>
                        <RoundedButton
                          onClick={handleClose}
                          options={{
                            title: "Cancel",
                          }}
                          theme={{
                            color: "#FD6864",
                            colorChange: "#b14846",
                          }}
                        />
                      </Controls>
                    )
                  ) : null}
                </Header>
              )}
            </Desktop>
            <Mobile>{mobileHeader && mobileHeader}</Mobile>
            {needScroll ? (
              <Scrollbar
                translateContentSizesToHolder
                style={{maxHeight: "100%"}}
                noScrollX
              >
                <Body
                  type={type}
                  bodyScroll={bodyScroll}
                  needScroll
                  noBodyPadding={noBodyPadding}
                  overflow={overflow}
                >
                  {children}
                </Body>
              </Scrollbar>
            ) : (
              <Body
                type={type}
                bodyScroll={bodyScroll}
                noBodyPadding={noBodyPadding}
                overflow={overflow}
              >
                {children}
              </Body>
            )}
            {type === "CENTER_SCREEN" && !noFooter && (
              <Footer footerJustifyContent={footerJustifyContent}>
                {footer && footer}
                {renderButtons && (
                  <Desktop>
                    <ActionsWrapper>
                      <Buttons items={buttons} type={"rounded"} />
                    </ActionsWrapper>
                  </Desktop>
                )}
              </Footer>
            )}
          </Content>
        </StyledModal>
      </CSSTransition>
    );
  }
);

Modal.displayName = "Modal";

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({type}) =>
    type === "CENTER_SCREEN" ? "rgba(0, 0, 0, 0.5)" : "none"};
  z-index: ${({theme, zIndex}) => (zIndex ? zIndex : theme.variables["z-index-modal"])};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.md}px) {
    height: 100%;
  }
`;

const Content = styled.div`
  width: ${({type, width}) =>
    type === "CENTER_SCREEN" ? (width ? width + "px" : "auto") : "100%"};
  max-width: ${({type, width}) =>
    type === "CENTER_SCREEN" && width
      ? width + "px"
      : type === "CENTER_SCREEN"
      ? "768px"
      : "auto"};
  height: ${({type}) => (type === "CENTER_SCREEN" ? "auto" : "100vh")};
  max-height: ${({type}) => (type === "CENTER_SCREEN" ? "calc(100% - 60px)" : "auto")};
  border-radius: ${({type}) => (type === "CENTER_SCREEN" ? "4px" : "0")};
  display: flex;
  flex-direction: column;
  background-color: ${({type}) =>
    type === "CENTER_SCREEN" ? "#fff" : "rgba(31, 39, 65, .87)"};
  box-shadow: ${({type}) =>
    type === "CENTER_SCREEN"
      ? "0 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)"
      : "none"};
  backdrop-filter: blur(4px);
  padding: ${({type, padding}) =>
    type === "CENTER_SCREEN" ? "0" : padding || padding === 0 ? padding : "40px"};
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.lg - 1}px) {
    padding: ${({type, padding}) =>
      type === "CENTER_SCREEN" ? "0" : padding || padding === 0 ? padding : "20px"};
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.md}px) {
    padding: 0;
    overflow-y: ${({bodyScroll}) => (bodyScroll ? "" : "scroll")};
    -webkit-overflow-scrolling: ${({bodyScroll}) => (bodyScroll ? "" : "touch")};
    height: ${({type}) => (type === "FULL_SCREEN" ? "100%" : null)};
    background-color: ${({type, backgroundColor}) =>
      type === "CENTER_SCREEN"
        ? "#fff"
        : backgroundColor || backgroundColor === 0
        ? backgroundColor
        : "rgba(255, 255, 255, .87)"};
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.md}px) {
    height: ${({type}) => (type === "CENTER_SCREEN" ? "100%" : null)};
    max-height: ${({type}) => (type === "CENTER_SCREEN" ? "100vh" : null)};
    border-radius: ${({type}) => (type === "CENTER_SCREEN" ? "0" : null)};
    width: ${({type}) => (type === "CENTER_SCREEN" ? "100%" : null)};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({type}) => (type === "CENTER_SCREEN" ? "16px 24px" : "0")};
  flex: 0 0 auto;
`;

const Title = styled.div`
  position: relative;
  font-family: ${({type}) =>
    type === "CENTER_SCREEN"
      ? "Avenir-medium, Arial-regular, sans-serif"
      : "Montserrat-bold, Arial-bold, sans-serif"};
  font-size: ${({type}) => (type === "CENTER_SCREEN" ? "20px" : "33px")};
  line-height: 1.55;
  color: ${({type}) => (type === "CENTER_SCREEN" ? "rgba(0, 0, 0, 0.87)" : "#fff")};
  flex: 1 1 0;
`;

const Body = styled.div`
  padding: ${({type, noBodyPadding}) =>
    noBodyPadding ? "0" : type === "CENTER_SCREEN" ? "8px 32px" : "0"};
  flex: 1 1 auto;
  width: 100%;
  @media screen and (min-width: ${({theme}) => theme.breakpoints.values.md}px) {
    display: flex;
  }

  > div {
    width: 100%;
  }

  @media screen and (min-width: ${({theme}) => theme.breakpoints.values.md}px) {
    overflow: ${({needScroll, overflow}) => (overflow || needScroll ? "unset" : "auto")};
    height: 100%;
  }
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.md - 1}px) {
    overflow-y: ${({bodyScroll}) => (bodyScroll ? "scroll" : "")};
    -webkit-overflow-scrolling: ${({bodyScroll}) => (bodyScroll ? "touch" : "")};
  }
`;

const IconWrapper = styled.div`
  margin-right: 32px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({footerJustifyContent}) =>
    footerJustifyContent ? footerJustifyContent : "space-between"};
  padding: 8px 32px;
  flex-wrap: wrap;
  @media screen and (max-width: ${({theme}) => theme.breakpoints.values.sm - 1}px) {
    padding: 8px 16px;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

Modal.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.array,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["FULL_SCREEN", "CENTER_SCREEN"]),
  timeout: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  footer: PropTypes.element,
  onSave: PropTypes.func,
  customHeader: PropTypes.element,
  mobileHeader: PropTypes.element,
  noFooter: PropTypes.bool,
  renderButtons: PropTypes.bool,
  width: PropTypes.number,
  overflow: PropTypes.string,
  theme: PropTypes.object,
  bodyScroll: PropTypes.bool,
  noBodyPadding: PropTypes.bool,
  needScroll: PropTypes.bool,
  disableHandleHide: PropTypes.bool,
  saveButtonDisabled: PropTypes.bool,
  footerJustifyContent: PropTypes.string,
};

Modal.defaultProps = {
  title: "",
  type: "CENTER_SCREEN",
  timeout: 300,
  icon: "",
  onSave: () => null,
  customHeader: null,
  noFooter: false,
  renderButtons: true,
  mobileHeader: null,
  bodyScroll: false,
  disableHandleHide: false,
  saveButtonDisabled: false,
  footerJustifyContent: "",
};

export {Modal};
