import React, {memo} from "react";

const Heroes2 = memo(() => (
  <svg viewBox="0 0 380 176">
    <g>
      <rect fill="#defafa" width="380" height="176" rx="3.83" ry="3.83" />
      <circle fill="#069696" cx="188.88" cy="64.5" r="39" />
      <circle fill="#fff" cx="190" cy="60.08" r="6.92" />
      <path
        fill="#fff"
        d="M189.69,43.25A22.25,22.25,0,1,0,211.94,65.5,22.25,22.25,0,0,0,189.69,43.25ZM205,76.35c-.53-2.74-2.22-8.81-6.7-8.81-4,0-3.54,3.5-8.25,3.5s-4.25-3.5-8.25-3.5c-4.9,0-6.46,7.25-6.82,9.48a18.75,18.75,0,1,1,30-.67Z"
      />
      <rect fill="#069696" x="127.67" y="128.33" width="126.5" height="6.17" />
      <rect fill="#84d1d1" x="127.67" y="143.71" width="126.5" height="2.54" />
      <rect fill="#84d1d1" x="127.67" y="154.71" width="126.5" height="2.54" />
    </g>
  </svg>
));

Heroes2.displayName = "Heroes2";

export default Heroes2;
