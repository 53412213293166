import React, {memo} from "react";
import styled, {keyframes} from "styled-components";
import PropTypes from "prop-types";

const Loader = memo(({color, height}) => (
  <LoaderWrapper height={height}>
    <LoaderContent color={color}>
      <LoaderElement />
      Loading
    </LoaderContent>
  </LoaderWrapper>
));

Loader.displayName = "Loader";

const animation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`;

const LoaderWrapper = styled.div`
  height: ${({height}) => height};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderElement = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border-width: 2px;
  border-style: solid;
  border-image: initial;
  border-color: #00c4cc #00c4cc transparent;
  display: inline-block;
  animation: 0.75s linear 0s infinite normal both running ${animation};
  background: transparent !important;
  margin-bottom: 20px;
`;

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px auto;
  color: ${({color}) => color};
  font-size: 14px;
  font-weight: bold;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratBold};
  text-align: center;
`;

Loader.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
};
Loader.defaultPros = {
  color: "#000",
  height: "100vh",
};
export {Loader};
