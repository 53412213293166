export const masonryStyles = `
.masonry-item {
  margin: 12px;  
}

.masonry-item1 {
    width: 100%;
}

.masonry-item2 {
    width: 50%;
}

.masonry-item3 {
    width: 33.33%;  
}

.masonry-item4 {
    width: 25%;
}

.masonry-item5 {
    width: 20%;
}
@media all and (max-width: 768px) {
  .masonry-item3 {
      width: 100%;  
  }
  .masonry-item3 img {
    max-height: unset !important;
  }
}
`;
