import React, {memo} from "react";

export const IconWidth = memo(() => (
  <svg x="0px" y="0px" viewBox="0 0 20 20">
    <path
      d="M16.5,0h-13C1.6,0,0,1.6,0,3.5v13C0,18.4,1.6,20,3.5,20h13c1.9,0,3.5-1.6,3.5-3.5v-13C20,1.6,18.4,0,16.5,0z
	 M15.1,15.1v-3.3c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1v4.4c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3h0h-4.4
	c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1C11.8,15.1,15.1,15.1,15.1,15.1z M4.9,4.9v3.3c0,0.6-0.5,1.1-1.1,1.1
	c-0.6,0-1.1-0.5-1.1-1.1V3.8v0v0C2.7,3.5,2.8,3.2,3,3c0.2-0.2,0.5-0.3,0.8-0.3h4.4c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1H4.9
	L4.9,4.9z"
    />
  </svg>
));
