import {defaultContent, defaultHeroProps} from "config/consts";
import cloneDeep from "lodash/cloneDeep";

import {defaultTextElement} from "config/consts";
import set from "lodash/set";

export const prepareTextContent = (content) => {
  return content.text.map((item) => {
    if (!item) return defaultTextElement;
    if (typeof item === "string") return item;
    const result = {
      text: {
        ops: item?.text?.ops?.map((element) => {
          return {
            ...element,
            insert: element.insert ? element.insert : "\n",
            attributes:
              element.attributes && !Array.isArray(element.attributes)
                ? element.attributes
                : {},
          };
        }),
      },
    };
    if (item?.textBackgroundColor) {
      result.textBackgroundColor = item.textBackgroundColor;
    }
    if (item?.elementMargins) {
      result.elementMargins = item.elementMargins;
    }
    if (item?.textPaddings) {
      result.textPaddings = item.textPaddings;
    }
    return result;
  });
};

export const prepareCustomContent = (content) => {
  return content.custom.map((item) => {
    if (item.type === "chart") {
      return {
        ...item,
        props: {
          ...item.props,
          series: item.props.series.map((el) => {
            return {
              ...el,
              name: el.name === null ? "" : el.name,
            };
          }),
          plotOptions: Array.isArray(item.props.plotOptions)
            ? {}
            : item.props.plotOptions,
          tooltip: Array.isArray(item.props.tooltip) ? {} : item.props.tooltip,
        },
      };
    }
    return item;
  });
};

export const prepareContent = (blockContent) => {
  const content = cloneDeep(blockContent);
  content.text = prepareTextContent(content);
  content.custom = prepareCustomContent(content);
  return content;
};

export const prepareBlock = (block, isHero = false) => {
  return {
    id: block.id,
    ifolio_id: block.ifolio_id,
    is_lock: block.is_lock === null ? false : block.is_lock,
    layout: block.layout.layout || block.layout,
    content: block.content
      ? prepareContent(block.content)
      : block.layout.content
      ? prepareContent(block.layout.content)
      : defaultContent,
    props: block.props
      ? Array.isArray(block.props) || (!isHero && Array.isArray(block.props.background))
        ? {...block.props, background: {}}
        : block.props
      : defaultHeroProps,
    cols: block.cols,
    layout_id: block.layout_id,
    name: block.name,
    compatibility_content_group: block.layout?.compatibility_content_group,
    is_content_dirty: block.is_content_dirty,
  };
};

export const prepareStructureBlock = (block) => {
  let item = {...block};
  if (block.content) {
    block.content.custom.forEach(({type, props}, idx) => {
      if (type === "chart") {
        set(item.content, `custom[${idx}].props`, {
          ...props,
          plotOptions:
            !props.plotOptions || Array.isArray(props.plotOptions)
              ? {}
              : props.plotOptions,
        });
      }
    });
    // item.content = {...defaultContent};
  }
  // else {
  //   block.content.custom.forEach(({type, props}, idx) => {
  //     if (type === "chart") {
  //       set(item.content, `custom[${idx}].props`, {
  //         ...props,
  //         plotOptions:
  //           !props.plotOptions || Array.isArray(props.plotOptions)
  //             ? {}
  //             : props.plotOptions,
  //       });
  //     }
  //   });
  // }
  return prepareBlock(item);
};

export const prepareChartConfig = (config) => {
  const chartConfig = {...config};
  if (chartConfig?.chart?.type === "columnline") {
    const duplicateSeries = cloneDeep(chartConfig.series);
    duplicateSeries.forEach((item) => {
      item.type = "column";
      item.hideDuplicateSeries = true;
    });
    chartConfig.series = [...chartConfig.series, ...duplicateSeries];
  }
  return chartConfig;
};
