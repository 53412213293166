import React from "react";
import styled, {css} from "styled-components";

const Item = ({icon, label, options}) => {
  return (
    <ItemWrapper options={options}>
      <IconWrapper>{icon}</IconWrapper>
      <Label>{label}</Label>
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({options}) =>
    options?.width &&
    css`
      flex: 0 0 calc(${options?.width} - 20px);
    `}
`;
const IconWrapper = styled.div`
  margin-right: 28px;
`;
const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-family: ${({theme}) => theme.fonts.fontFamily.helveticaNeueLight};
`;

export default Item;
