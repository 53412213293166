import React from "react";
import styled from "styled-components";
import Item from "./Item";

const HelperContent = ({title, text, addition, options}) => {
  return (
    <HelperContentWrapper width={options?.width}>
      <Title dangerouslySetInnerHTML={{__html: title}} />
      <Text dangerouslySetInnerHTML={{__html: text}} />
      {addition && (
        <Addition>
          {addition.map((el) => (
            <Item key={el.id} {...el} />
          ))}
        </Addition>
      )}
    </HelperContentWrapper>
  );
};

const HelperContentWrapper = styled.div`
  min-width: 245px;
  max-width: 500px;
  width: ${({width}) => width}px;
`;
const Title = styled.div`
  text-align: center;
  font-size: 16px;
  letter-spacing: -0.15px;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratBold};
  margin-bottom: 14px;
`;
const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-family: ${({theme}) => theme.fonts.fontFamily.helveticaNeueLight};
`;
const Addition = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 20px 40px;
`;

export default HelperContent;
