import React, {memo} from "react";

const CropIcon = memo(() => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32">
      <path
        fill="#FFF"
        d="M4.267 8.6c0-.258.209-.467.466-.467h2.334V10H4.733c-.257 0-.466-.209-.466-.467V8.6zm18.2-.467c.257 0 .466.21.466.467v16.8c0 .258-.209.467-.466.467h-.934c-.257 0-.466-.21-.466-.467V10H10.8V8.133h11.667zm-12.6 2.8V21.2h10.266v1.867H8.467c-.258 0-.467-.21-.467-.467V5.8c0-.258.209-.467.467-.467H9.4c.258 0 .467.21.467.467v5.133zM26.2 21.2c.258 0 .467.209.467.467v.933c0 .258-.21.467-.467.467h-2.333V21.2H26.2z"
      />
    </svg>
  );
});

CropIcon.displayName = "CropIcon";

export default CropIcon;
