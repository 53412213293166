import React, {memo} from "react";

export const IconBlockDelete = memo(() => (
  <svg x="0px" y="0px" viewBox="0 0 20 20">
    <path
      d="M17.6,4.9v13.9c0,0.6-0.5,1.1-1.1,1.1H4.3c-0.6,0-1.1-0.5-1.1-1.1V4.9H17.6z M12.9,0c0.3,0,0.6,0.3,0.6,0.6V1
    h4.2c0.4,0,0.7,0.3,0.7,0.7v1.4c0,0.4-0.3,0.7-0.7,0.7H3.2c-0.4,0-0.7-0.3-0.7-0.7V1.7C2.5,1.3,2.8,1,3.2,1h4.2V0.6
    C7.4,0.3,7.6,0,8,0L12.9,0z M7.5,8.1C7.3,8,7.2,7.9,7,7.9S6.6,8,6.5,8.1C6.4,8.3,6.3,8.4,6.3,8.6c0,0.2,0.1,0.4,0.2,0.5l3,3l-3,3
    c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2l3-3l3,3c0.1,0.1,0.3,0.2,0.5,0.2
    c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5l-3-3l3-3c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5
    c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2l-3,3C10.5,11.1,7.5,8.1,7.5,8.1z"
    />
  </svg>
));
