export const printClasses = `
@media print {
    body {
        max-width: 1500px;
        min-width: 1500px;
        width: 1500px;
    }
     @page {
        margin: 1cm;
        size: A4 landscape;        
    }
    .grid-sortable-container {
        display: block;
    }    
 }
`;
