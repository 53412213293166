import styled, {css} from "styled-components";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import ArrowIcon from "../Icons/ArrowIcon";
import {Icon} from "../Icon";
import {useDispatch, useSelector} from "react-redux";
import {updateUser} from "../../actions/user";
// import {publishUnpublishFolio} from "../../actions/folio";
// import {setFolioConfig} from "../../reducers/folio";
import {hide} from "redux-modal";
import {publishUnpublishFolio} from "../../actions/folio";
import {setFolioConfig} from "../../reducers/folio";
import {AppActions} from "../../reducers/app";

const HelperTooltip = memo(
  ({children, box, setPaused, setDone, handleStep, config, step, hideArrow = false}) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.profile.id);
    const folioId = useSelector((state) => state.app.folioId);
    const [position, setPosition] = useState({
      x: -9999,
      y: -9999,
      position: "top",
      diffX: 0,
    });

    const ref = useRef(null);

    const calculatePosition = useCallback(
      ({position = "bottom", box, helperRef, ignoreChangePosition = false}) => {
        let diffX = 0;
        if (position === "left") {
          return {
            x: box.left - helperRef.width - 10,
            y: box.top - helperRef.height / 2 + box.height / 2,
            position,
            diffX,
          };
        } else if (position === "right") {
          return {
            x: box.left + box.width + 10,
            y: box.top - helperRef.height / 2 + box.height / 2,
            position,
            diffX,
          };
        }
        let newPosition = position;
        let x = box.left - helperRef.width / 2 + box.width / 2;
        if (x + helperRef.width > window.innerWidth) {
          const newX = window.innerWidth - helperRef.width - 15;
          diffX = x - newX;
          x = newX;
        } else if (x < 0) {
          diffX = x - 15;
          x = 15;
        }
        let y;
        if (position === "top") {
          y = box.top - helperRef.height - 10;
          if (!ignoreChangePosition && y < 0) {
            y = box.top + box.height + 10;
            newPosition = "bottom";
          }
        } else {
          y = box.top + box.height + 10;
          if (!ignoreChangePosition && y + helperRef.height > window.innerHeight) {
            y = box.top - helperRef.height - 15;
            newPosition = "top";
          }
        }

        return {
          x,
          y,
          position: newPosition,
          diffX,
        };
      },
      []
    );

    useEffect(() => {
      const helperRef = ref.current.getBoundingClientRect();
      setPosition(
        calculatePosition({
          position: config[step].position,
          box,
          helperRef,
          ignoreChangePosition: config[step].ignoreChangePosition,
        })
      );
    }, [box, box.height, box.left, box.top, box.width, calculatePosition, config, step]);

    const handleDone = useCallback(async () => {
      setDone();
      const response = await publishUnpublishFolio({
        id: folioId,
        is_published: false,
      });
      dispatch(hide("dashboard-modal"));
      dispatch(updateUser({show_tutorial: false, show_mobile_tutorial: false}, userId));
      dispatch(AppActions.setFolioPublishStatus("process"));
      dispatch(setFolioConfig(response.data.data));
    }, [dispatch, folioId, setDone, userId]);

    return (
      <>
        <HelperWrapper
          position={position.position}
          top={position.y}
          left={position.x}
          diffX={position.diffX}
          id={"helper-wrapper"}
          ref={ref}
          hideArrow={hideArrow}
        >
          {step !== 0 && (
            <FloatingButton disabled={step === 0} onClick={() => handleStep(-1)}>
              <Icon icon={ArrowIcon} />
            </FloatingButton>
          )}
          {children}
          <Footer>
            <Button onClick={() => setPaused(true)} hollow>
              Skip
            </Button>
            {config.length - 1 > step && (
              <>
                {step !== config.length - 1 && (
                  <Button
                    disabled={step === config.length - 1}
                    onClick={() => handleStep(1)}
                  >
                    {config[step]?.nextTitle || "Next"}
                  </Button>
                )}
              </>
            )}
            {config.length - 1 <= step && <Button onClick={handleDone}>Finish</Button>}
          </Footer>
        </HelperWrapper>
        <Overlay />
      </>
    );
  }
);

HelperTooltip.displayName = "HelperTooltip";

const HelperWrapper = styled.div`
  position: absolute;
  background: #1f2741;
  border: 2px solid #20dac4;
  box-shadow: 0 2px 7px 0 rgba(127, 142, 154, 0.45);
  border-radius: 7px;
  color: #fff;
  padding: 16px;
  top: ${({top}) => top}px;
  left: ${({left}) => left}px;
  z-index: 100000;
  ${({hideArrow}) =>
    !hideArrow &&
    css`
    &:before {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #1f2741 transparent;
    left: calc(50% - 15px + ${({diffX}) => `${diffX}px`});
    top: -14px;
    z-index: 1;
    ${({position}) =>
      position === "top" &&
      css`
        left: calc(50% - 15px + ${({diffX}) => `${diffX}px`});
        bottom: -14px;
        top: auto;
        border-width: 15px 15px 0 15px;
        border-color: #1f2741 transparent transparent transparent;
      `}
    ${({position}) =>
      position === "left" &&
      css`
        right: -14px;
        top: calc(50% - 15px);
        left: auto;
        border-width: 15px 0 15px 15px;
        border-color: transparent transparent transparent #1f2741;
      `}
    ${({position}) =>
      position === "right" &&
      css`
        left: -14px;
        top: calc(50% - 15px);
        border-width: 15px 15px 15px 0;
        border-color: transparent #1f2741 transparent transparent;
      `}
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #20dac4 transparent;
    left: calc(50% - 15px + ${({diffX}) => `${diffX}px`});
    top: -17px;
    ${({position}) =>
      position === "top" &&
      css`
        left: calc(50% - 15px + ${({diffX}) => `${diffX}px`});
        bottom: -17px;
        top: auto;
        border-width: 15px 15px 0 15px;
        border-color: #20dac4 transparent transparent transparent;
      `}
    ${({position}) =>
      position === "left" &&
      css`
        right: -17px;
        top: calc(50% - 15px);
        left: auto;
        border-width: 15px 0 15px 15px;
        border-color: transparent transparent transparent #20dac4;
      `}
    ${({position}) =>
      position === "right" &&
      css`
        left: -17px;
        top: calc(50% - 15px);
        border-width: 15px 15px 15px 0;
        border-color: transparent #20dac4 transparent transparent;
      `}
  }
  `}
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  gap: 8px;
`;

const Button = styled.button`
  color: #fff;
  border-radius: 7px;
  border: ${({hollow}) => (hollow ? "2px solid #fff" : "none")};
  background-color: ${({hollow}) => (hollow ? "transparent" : "#20dac4")};
  background: ${({hollow}) =>
    hollow
      ? null
      : "linear-gradient(90deg, rgba(32,218,196,1) 4%, rgba(0,196,204,1) 96%)"};
  padding: ${({hollow}) => (hollow ? "8px 23px" : "10px 23px")};
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 1.3px;
  font-family: ${({theme}) => theme.fonts.fontFamily.montserratSemibold};
  text-transform: uppercase;
  cursor: pointer;
`;

const FloatingButton = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div``;

export default HelperTooltip;
