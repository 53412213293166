import React, {memo} from "react";

const MediumHeaderIcon = memo(() => {
  return (
    <svg width="170px" height="108px" viewBox="0 0 170 108">
      <rect fill={"#fff"} width={170} height={108} />
      <g transform="translate(-39.000000, -145.000000)">
        <g transform="translate(39.000000, 145.000000)">
          <rect fill="#DEFAFA" x="0" y="0" width="173" height="65" />
          <g transform="translate(73.000000, 20.000000)" fill="#069696">
            <path d="M23.2195558,18.6735939 L0.621234789,18.6735939 C0.403018163,18.6735939 0.20080207,18.5590994 0.0885305423,18.3719802 C-0.0237409856,18.184861 -0.0296035357,17.9525561 0.0730866413,17.760012 L6.30735825,5.94193793 C6.4102234,5.7484662 6.6074032,5.62349308 6.82627183,5.61304904 C7.04406287,5.59936285 7.25274993,5.70231513 7.37441997,5.8834688 L12.3442965,13.2651972 L15.9986175,9.6108762 C16.1222577,9.48871353 16.2914212,9.42393435 16.4650333,9.43226774 C16.6386454,9.44060112 16.8008267,9.52128484 16.9121978,9.65472805 L23.6873089,17.6503824 C23.8441011,17.835132 23.8791627,18.0941869 23.7771214,18.3139682 C23.6750801,18.5337495 23.4545609,18.6741418 23.2122471,18.6735939 L23.2195558,18.6735939 Z M1.68829652,17.4311231 L21.8747657,17.4311231 L16.4225187,10.9995182 L12.6878027,14.6538392 C12.5562523,14.7831611 12.3740326,14.8473307 12.1904767,14.8289752 C12.0069209,14.8106196 11.8410164,14.7116375 11.7376792,14.5588268 L6.93590146,7.41097497 L1.68829652,17.4311231 Z"></path>
            <path d="M18.2058274,7.95181447 C16.595118,7.96365825 15.1364119,7.00250638 14.511796,5.51779088 C13.8871802,4.03307538 14.2200874,2.31819659 15.3548512,1.17502719 C16.4896149,0.0318577849 18.2019902,-0.313696127 19.6912749,0.299945523 C21.1805596,0.913587174 22.152494,2.3651603 22.152494,3.97591324 C22.1485807,6.15873763 20.3885635,7.93179204 18.2058274,7.95181447 L18.2058274,7.95181447 Z M18.2058274,1.24248114 C16.6961945,1.24248114 15.4723953,2.46628038 15.4723953,3.97591324 C15.4723953,5.4855461 16.6961945,6.70934534 18.2058274,6.70934534 C19.7154602,6.70934534 20.9392595,5.4855461 20.9392595,3.97591324 C20.9392595,2.46509354 19.7166417,1.23920135 18.2058274,1.2351725 L18.2058274,1.24248114 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
});

MediumHeaderIcon.displayName = "MediumHeaderIcon";

export default MediumHeaderIcon;
