import React, {memo} from "react";

export const IconMove = memo(() => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" fill="none">
    <path
      d="M11.776 13.333v2.26h2.035c.286 0 .43.315.23.516l-3.812 3.805c-.114.115-.315.115-.43 0L5.99
    16.11c-.172-.172-.058-.515.229-.515h2.034v-2.261h3.524zm-7.85-7.324c.171-.172.515-.058.515.228V8.04h3.496c.022
    0 .043-.002.064-.007L8 11.595l-.063-.007H4.441v2.232c0 .286-.315.429-.515.229l-3.84-3.834c-.115-.115-.115-.315
    0-.43zm11.69.2c0-.286.315-.43.516-.229l3.782 3.805c.115.115.115.315 0
    .43l-3.81 3.805c-.173.172-.517.057-.517-.229V11.56h-2.254V8.011h2.283zM9.828.086c.115-.115.315-.115.43 0l3.81
    3.834c.173.172.058.515-.228.515h-2.035v2.231H8.252V4.435H6.218c-.287 0-.43-.315-.23-.515z"
    />
  </svg>
));
