import React, {memo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {Icon} from "../Icon";

const ButtonLayout = memo(
  ({icon, size, padding, margin, color, onClick, highlighted, id}) => {
    return (
      <IconButtonStyled
        margin={margin}
        onClick={onClick}
        highlighted={highlighted}
        id={id}
      >
        <Icon icon={icon} size={size} color={color} padding={padding} />
      </IconButtonStyled>
    );
  }
);

ButtonLayout.displayName = "ButtonLayout";

const IconButtonStyled = styled.div`
  outline: none;
  opacity: 0.8;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  background: ${({highlighted}) => (highlighted ? "#25BEC8" : "#1f2741")};
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: ${({padding}) => padding}px;
  margin: ${({margin}) => margin}px;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover,
  &:active {
    background: rgba(37, 190, 200, 0.6);
  }

  &:focus {
    outline: none;
  }

  svg {
    z-index: 1;
  }
`;

ButtonLayout.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  size: PropTypes.number,
  opacity: PropTypes.number,
  padding: PropTypes.number,
  color: PropTypes.string,
  margin: PropTypes.number,
  onClick: PropTypes.func,
  element: PropTypes.any.isRequired,
  highlighted: PropTypes.bool,
};

ButtonLayout.defaultProps = {
  size: 20,
  padding: 8,
  margin: 0,
  color: "#fff",
  onClick: () => null,
  element: "",
  highlighted: false,
};

export {ButtonLayout};
