import React, {memo} from "react";

const RangeMonthIcon = memo(() => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        fill="#FFF"
        d="M21,6 L21,20 C21,21.1 20.1,22 19,22 L5,22 C3.89,22 3,21.1 3,20 L3.01,6 C3.01,4.9 3.89,4 5,4 L6,4 L6,2 L8,2 L8,4 L16,4 L16,2 L18,2 L18,4 L19,4 C20.1,4 21,4.9 21,6 Z M5,8 L19,8 L19,6 L5,6 L5,8 Z M19,20 L19,10 L5,10 L5,20 L19,20 Z"
      />
      <polygon
        fill="#FFF"
        fillRule="nonzero"
        points="12 15.25 7 19 7 11 9 11 9 15 12 12.75 15 15 15 11 17 11 17 19"
        transform="matrix(1 0 0 -1 0 30)"
      />
    </g>
  </svg>
));

export default RangeMonthIcon;
