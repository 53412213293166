import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  data: [],
  links: {},
  meta: {},
  isFetching: false,
  isUploading: false,
  isLinkUploading: false,
  custom: {
    currentTabs: null,
  },
  callbackData: null,
  isFirstOpenQuery: false,
  filters: {
    leftMenuActiveItem: "",
    rightMenuActiveItem: "all_without_documents",
    search: "",
  },
};

export const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.data = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setIsUploading: (state, action) => {
      state.isUploading = action.payload;
    },
    setIsLinkUploading: (state, action) => {
      state.isLinkUploading = action.payload;
    },
    clearAll: (state) => {
      state.data = initialState.data;
      state.links = initialState.links;
      state.meta = initialState.meta;
      state.isFetching = initialState.isFetching;
      state.custom = initialState.custom;
    },
    setMediaMenuCurrentTabs: (state, action) => {
      state.custom.currentTabs = action.payload;
    },
    setCallbackData: (state, action) => {
      state.callbackData = action.payload;
    },
    setIsFirstOpenQuery: (state, action) => {
      state.isFirstOpenQuery = action.payload;
    },
    setFilters: (state, action) => {
      state.filters.leftMenuActiveItem = action.payload.leftMenuActiveItem;
      state.filters.rightMenuActiveItem = action.payload.rightMenuActiveItem;
      state.filters.search = "";
    },
    setFilterByType: (state, action) => {
      state.filters = {
        ...state.filters,
        [action.payload.key]: action.payload.value,
      };
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const MediaActions = mediaSlice.actions;
