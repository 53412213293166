import replace from "lodash/replace";

export const getParameterByName = (name, loc) => {
  const url = loc ? loc : window.location.href;
  // eslint-disable-next-line
  name = replace(name, /[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return null;
  const res = decodeURIComponent(replace(results[2], /\+/g, " "));
  if (res[res.length - 1] === "/") {
    return decodeURIComponent(replace(replace(results[2], /\+/g, " ")), "/", "");
  }
  return decodeURIComponent(replace(results[2], /\+/g, " "));
};
