import React, {memo} from "react";

const Heroes5 = memo(() => (
  <svg viewBox="0 0 380 176">
    <rect fill="#defafa" width="380" height="176" rx="3.83" ry="3.83" />
  </svg>
));

Heroes5.displayName = "Heroes5";

export default Heroes5;
