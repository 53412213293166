import {createSlice} from "@reduxjs/toolkit";

export const statisticsSlice = createSlice({
  name: "app",
  initialState: {
    users: {},
    sessions: {},
    pageViews: {},
    devices: [],
    newUsers: {},
    avgTimeOnPage: {},

    social: [],
    videoPlay: [],
    clicks: [],
    companies: [],
    leaderBoard: [],
    scrollmap: {},
    sharedstats: {},
    currentShare: null,
    sharedStatsScrollmap: {},
    summary: {},
  },
  reducers: {
    setStatistics: (state, action) => {
      state[action.payload.key] = action.payload.data;
    },
  },
});

export const StatisticsActions = statisticsSlice.actions;
